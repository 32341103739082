import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule} from '@angular/core';
import { AppComponent } from './app.component';
import {NZ_I18N, zh_CN } from 'ng-zorro-antd';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { registerLocaleData } from '@angular/common';
import zh from '@angular/common/locales/zh';
import {LayoutModule} from './layout/layout.module';
import {RoutesModule} from './routes/routes.module';
import {SharedModule} from '@shared/shared.module';
import {CoreModule} from '@core/core.module';
import {StartupService, StartupServiceFactory} from '@core/service/startup.service';
import {DefaultInterceptor} from '@core/net/default-interceptor.service';
import {DelonModule} from './delon.module';
import {JWTInterceptor} from '@delon/auth';
import {AbmModule} from 'angular-baidu-maps';

registerLocaleData(zh);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    SharedModule,
    LayoutModule,
    RoutesModule,
    DelonModule.forRoot(),
    CoreModule,
    AbmModule.forRoot({
      apiKey: 'E06eb9d756d0eafc722effb355657b4c'
    })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JWTInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: DefaultInterceptor,
      multi: true
    },
    StartupService,
    {
      provide: APP_INITIALIZER,
      useFactory: StartupServiceFactory,
      deps: [StartupService],
      multi: true
    },
    {
      provide: NZ_I18N,
      useValue: zh_CN
    }],
  bootstrap: [AppComponent]
})
export class AppModule { }
