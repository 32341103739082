import { Component } from '@angular/core';
import { NotifyPushService } from '@core/service/push.service';

@Component({
  selector: 'app-root',
  template: `<router-outlet></router-outlet>`
})
export class AppComponent {
  title = 'lee-manage';
  constructor(
    // tslint:disable-next-line:variable-name
    private _nps: NotifyPushService
  ) {
    this._nps.requestPermission();
  }
}
