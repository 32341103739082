import { Injectable } from '@angular/core';
import {HttpService} from '@core/service/http.service';
import {UtilService} from '@core/service/util.service';
import {Actions, GridParams} from '@shared/model/baseModel';
import {REQUEST} from '@core/config/config';
import {BaseForm, DropdownForm, SingleRadioForm, TextboxForm} from '@shared/model/baseForm';

@Injectable({
  providedIn: 'root'
})
export class OrderDetailService {

  constructor(
    private http: HttpService,
    private util: UtilService
  ) { }
  get initBaseSearch(): {apply_name, state, times} {
    return {
      apply_name: null,
      state: null,
      times: null
    };
  }

  get initGridParams(): GridParams {
    return {
      url: REQUEST.INVOICE.ALL,
      listType: 'invoiceResourceList'
    };
  }

  getActions({query, reset, review}): Actions[] {
    return [
      {
        id: 100300001,
        name: '查询',
        iconCls: 'search',
        click: query
      },
      {
        id: 100300001,
        name: '重置',
        iconCls: 'redo',
        click: reset
      },
      {
        id: 100300002,
        name: '审核',
        type: 'primary',
        isEnd: true,
        isOperate: true,
        click: review
      }
    ];
  }

  get getBaseInfo(): any {
    return [
      {key: 'applyCode', value: '申请单号'},
      {key: 'draweeIdentity', value: '受票方纳税识别号'},
      {key: 'draweeName', value: '受票方名称'},
      {key: 'address', value: '地址'},
      {key: 'bankName', value: '开户行'},
      {key: 'bankAccount', value: '开户账号'},
      {key: 'khrxm', value: '开户人姓名'},
      {key: 'khrsfzjhm', value: '开户人身份证号'},
      {key: 'mark', value: '备注'}
    ];
  }

  getReviewFormJson = (model: any, errorList: any) => {

    const reviewFormJson: BaseForm<any>[] = [
      new SingleRadioForm({
        key: 'verifyState',
        label: '审批意见',
        value: model.state !== 3,
        required: true,
        options: [
          {code: true, value: '同意'},
          {code: false, value: '拒绝'}
        ],
        order: 1
      }),
      new DropdownForm({
        key: 'deny_code',
        label: '意见备注',
        placeholder: '请选择意见备注',
        value: !!errorList ? errorList : null,
        mode: 'multiple',
        required: model.state === 3,
        hidden: model.state !== 3,
        options: [
          {code: '0', value: '车辆北斗轨迹不全'},
          {code: '1', value: '车辆北斗轨迹无法获取'},
          {code: '2', value: '申诉通过'},
          {code: '3', value: '车辆GPS轨迹不全'},
          {code: '4', value: '车辆审核未通过'},
          {code: '5', value: '司机审核未通过'}
        ],
        order: 2
      }),
      new TextboxForm({
        key: 'memo',
        placeholder: '请填写备注',
        value: !!model.rejectMemo ? model.rejectMemo : null,
        hidden: model.state !== 3,
        order: 3
      }),
      new TextboxForm({
        key: 'invoice_code',
        label: '发票代码',
        placeholder: '请填写发票代码',
        value: null,
        required: model.state !== 3,
        hidden: model.state === 3,
        order: 4
      })
    ];
    return reviewFormJson.sort((a, b) => a.order - b.order);
  }

  get getLicenseInfo(): any {
    return [
      {key: 'suppleInfoPic', value: '补充资料'}
    ];
  }

  get getVerifyInfo(): any {
    return [
      { key: 'originalDocumentNumber', value: '原始单号' },
      { key: 'shippingNoteNumber', value: '运单号' },
      { key: 'vehicleAmount', value: '运输总车辆数' },
      { key: 'serialNumber', value: '分段分单号' },
      { key: 'transportTypeCode', value: '运输组织类型代码' },
      { key: 'sendToProDateTime', value: '运单上传时间' },
      { key: 'carrier', value: '网络货运经营者名称' },
      { key: 'unifiedSocialCreditIdentifier', value: '统一社会信用代码' },
      { key: 'permitNumber', value: '道路运输经营许可证编号' },
      { key: 'consignmentDateTime', value: '运单生成时间，' },
      { key: 'businessTypeCode', value: '业务类型代码' },
      { key: 'despatchActualDateTime', value: '发货日期时间' },
      { key: 'goodsReceiptDateTime', value: '收货日期时间，' },
      { key: 'consignorInfo', value: '托运人信息' },
      { key: 'consigneeInfo', value: '收货方信息' },
      { key: 'totalMonetaryAmount', value: '运费金额' },
      { key: 'vehicleInfo', value: '车辆信息' },
      { key: 'actualCarrierInfo', value: '实际承运人信息' },
      { key: 'insuranceInformation', value: '保险信息' },
      { key: 'consignor', value: '托运人名称' },
      { key: 'consignorID', value: '托运人统一社会信用代码' },
      { key: 'countrySubdivisionCode', value: '装货地点行政区划代码' },
      { key: 'consignee', value: '收货方名称' },
      { key: 'consigneeID ', value: '收货方统一社会信用代码' },
      { key: 'countrySubdivisionCode', value: '收货地点行政区划代码' },
      { key: 'vehiclePlateColorCode', value: '车牌颜色代码' },
      { key: 'vehicleNumber ', value: '车辆牌照号' },
      { key: 'driver', value: '驾驶员信息' },
      { key: 'goodsInfo', value: '货物信息，' },
      { key: 'driverName', value: '驾驶员姓名' },
      { key: 'drivingLicense', value: '驾驶员身份证号' },
      { key: 'driverLicenseNumber', value: '驾驶证编号' },
      { key: 'descriptionOfGoods', value: '货物名称' },
      { key: 'cargoTypeClassificationCode', value: '货物类型分类代码' },
      { key: 'goodsItemGrossWeight', value: '货物项毛重' },
      { key: 'actualCarrierName', value: '实际承运人名称' },
      { key: 'actualCarrierBusinessLicense', value: '实际承运人的道路运输经营许可证编号' },
      { key: 'actualCarrierID', value: '实际承运人统一社会信用代码' },
      { key: 'policyNumber', value: '保险单号' },
      { key: 'insuranceCompany', value: '保险公司名称代码' }
    ];
  }

  get getOrderInfo(): any {
    return [
      {key: 'carOrderCode', value: '运单编号'},
      {key: 'sync', value: '订单类型'},
      {key: 'title', value: '货物名称'},
      {key: 'cargoNum', value: '货物重量'},
      {key: 'unitWight', value: '单位重量'},
      {key: 'cargo', value: '货物实际重量'},
      {key: 'cargoType', value: '货物类型'},
      {key: 'goodsPropertyTypeCode', value: '货物分类'},
      {key: 'consignee', value: '收货人'},
      {key: 'consigneePhoneNum', value: '收货人电话'},
      {key: 'consigner', value: '发货人'},
      {key: 'fdsj', value: '发单时间'},
      {key: 'fdfs', value: '发单方式'},
      {key: 'codename', value: '货物所属行业'},
      {key: 'pickupEndTime', value: '提货最晚时间'},
      {key: 'unloadEndTime', value: '预计卸货时间'},
      {key: 'mobile', value: '司机手机号'},
      {key: 'name', value: '司机姓名'},
      {key: 'plateNum', value: '车牌号码'},
      {key: 'cargoAddress', value: '始发城市'},
      {key: 'cargoAddrDetail', value: '始发地详细地址'},
      {key: 'deliveryAddress', value: '目的城市'},
      {key: 'deliveryAddrDetail', value: '目的地详细地址'},
      {key: 'dcollection', value: '司机收款'},
      {key: 'cash', value: '剩余费用'},
      {key: 'orderMoney', value: '运费金额'},
      {key: 'prepayment', value: '装车预付费'},
      {key: 'receiptDeposit', value: '回单押金'},
      {key: 'creator', value: '建单人'},
      {key: 'etcMoney', value: 'ETC金额'},
      {key: 'refuelCardMoney', value: '油卡金额'}
    ];
  }

  get getOrderImgInfo(): any {
    return [
      {key: 'receiptPic', value: '回单照片(入库单)'},
      {key: 'unloadingPic', value: '卸货照片'},
      {key: 'pickupPic', value: '提货照片'}
    ];
  }

  getInvoiceModel(id: number) {
    return this.http.get(REQUEST.INVOICE.DETAIL, {
      invoice_id: id
    });
  }

  getReviewActions({checkDetail}): Actions[] {
    return [
      {
        id: 100300002,
        name: '查看详情',
        type: 'primary',
        isEnd: true,
        isOperate: true,
        click: checkDetail
      }
    ];
  }

  /**
   * 发票审核
   * @param params
   */
  review(params) {
    return this.http.put(REQUEST.INVOICE.ANLIAN, null, params);
  }

  deny(params: any, body: any) {
    return this.http.put(REQUEST.INVOICE.DENY, body, params);
  }

  pass(params) {
    return this.http.put(REQUEST.INVOICE.PASS, null, params);
  }

  //核验
  verificate(orderId: number) {
    return this.http.post(`${REQUEST.CAR.VERIFY}?type=3&id=${orderId}`, {});
  }

  orderDetail(orderId: number) {
    return this.http.get(`${REQUEST.ORDERS.ALL}${orderId}`, {});
  }
}
