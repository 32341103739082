import { Injectable } from '@angular/core';
import { RendererService } from '@core/service/renderer.service';
import { Actions } from '@shared/model/baseModel';
import { ActionsRendererComponent } from '@shared/component/base-grid/component/actions-renderer.component';
import { UtilService } from '@core/service/util.service';

@Injectable({
  providedIn: 'root'
})
export class GridColumnService {

  constructor(
    private renderer: RendererService,
    private util: UtilService
  ) { }

  /**
   * 基础表格项
   */
  get getBaseColumns() {
    return [
      {
        headerName: '',
        width: 50,
        headerCheckboxSelection: true,
        headerCheckboxSelectionFilteredOnly: true,
        checkboxSelection: true,
        pinned: true
      }
    ];
  }

  /**
   * 额外表格项
   */
  get getExtraColumns() {
    return [

    ];
  }

  /**
   * 操作表格项
   */
  getActionsColumns(actions: Actions[]): any {
    const showActions = this.util.showActionsColumns(actions);
    return [{
      headerName: '操作',
      hide: showActions.length <= 0,
      width: showActions.length * 65 + 25,
      // pinned: 'right',
      cellRendererFramework: ActionsRendererComponent,
      cellRendererParams: {
        actions: showActions
      }
    }];
  }

  /**
   * 车辆表格项
   */
  getCarColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '车牌号码', field: 'plateNum', width: 100 },
      { headerName: '所属公司', field: 'logisticsCompany', width: 200 },
      { headerName: '车辆类型', field: 'carType', width: 150 },
      { headerName: '是否定位', field: 'location', width: 100, cellRenderer: this.renderer.isBoolean },
      { headerName: '联系方式', field: 'driverMobile', width: 120 },
      { headerName: '状态', field: 'verifyState', width: 100, cellRenderer: this.renderer.verifyState },
      { headerName: '绑定司机', field: 'driverName', width: 150 },
      { headerName: '承运车辆品牌', field: 'cypp', width: 150 }
    ];
  }

  /**
   * 用户表格项
   */
  getUserColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '用户姓名', field: 'userName', width: 120 },
      { headerName: '手机号', field: 'userMobile', width: 120 },
      // {headerName: '角色', field: 'userMobile', width: 120},
      { headerName: '余额', field: 'money', width: 120 },
      { headerName: '状态', field: 'userStatus', width: 120, cellRenderer: this.renderer.userStatus },
      // { headerName: '最后一次登录时间', field: 'pwdExpireDate', width: 200 }
    ];
  }

  /**
   * 角色表格项
   */
  getRoleColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '角色名称', field: 'roleName', width: 200 },
      { headerName: '角色备注', field: 'memo', width: 200 },
      { headerName: '角色描述', field: 'roleDesc', width: 300 },
      { headerName: '角色类型', field: 'roleType', width: 150, cellRenderer: this.renderer.roleType }
    ];
  }

  /**
   * 未审核司机表格项&已审核司机表格项
   */
  getDriverColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '姓名', field: 'name', width: 100 },
      { headerName: '身份证号码', field: 'idNum', width: 180 },
      { headerName: '手机号', field: 'mobile', width: 120 },
      { headerName: '驾照类型', field: 'licenseType', width: 100 },
      { headerName: '驾照号码', field: 'driverLicence', width: 180 },
      { headerName: '驾驶证获取日期', field: 'licenseFirstDetDate', width: 130, cellRenderer: this.renderer.dateFormat('YYYY-MM-DD') },
      { headerName: '纳税人姓名', field: 'taxpayerName', width: 120 },
      { headerName: '纳税人身份证号码', field: 'taxpayerIdentity', width: 180 },
      { headerName: '纳税人开户行', field: 'bankName', width: 120 },
      { headerName: '纳税人银行卡号', field: 'bankAccout', width: 180 },
    ];
  }

  /**
   * 发票表格项
   */
  getInvoiceColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '申请编号', field: 'applyCode', width: 280 },
      { headerName: '申请人', field: 'applyName', width: 80 },
      { headerName: '金额', field: 'invoiceMoney', width: 100 },
      { headerName: '状态', field: 'state', width: 80, cellRenderer: this.renderer.invoiceState },
      { headerName: '申请时间', field: 'applyDateTime', width: 140, cellRenderer: this.renderer.dateFormat() },
      { headerName: '发票代码', field: 'invoiceCode', width: 160 },
      { headerName: '发票号码', field: 'invoiceNo', width: 160 },
      { headerName: '价税合计', field: 'invoiceMoney', width: 100 },
      { headerName: '开票日期', field: 'performDatetime', width: 140, cellRenderer: this.renderer.dateFormat('YYYY-MM-DD') },
      { headerName: '发票所属月份', field: 'invoiceMonth', width: 120 },
    ];
  }

  /**
   * 发票订单表格项
   */
  getOrderColumns() {
    // const showActions = this.util.showActionsColumns(actions);
    return [
      ...this.getBaseColumns,
      {
        headerName: '运单号', field: 'orderCode', width: 310,
        // cellRendererFramework: ActionsRendererComponent,
        // cellRendererParams: {
        //   actions: showActions
        // } 
      },
      {
        headerName: '车辆信息', field: 'driverName', width: 200,
        cellRenderer: (params) => {
          return params.data.plate_num + params.data.driverName + params.data.driverPhone;
        }
      },
      {
        headerName: '线路信息', field: 'deliveryAddrB', width: 200,
        cellRenderer: params => {
          return '起点:' + params.data.deliveryAddrB + ' 终点:' + params.data.deliveryAddrE;
        }
      },
      { headerName: '货物名称', field: 'title', width: 100 },
      { headerName: '货物数量', field: 'cargoNum', width: 100 },
      {
        headerName: '运输时间', field: 'deliveryBtm', width: 200,
        cellRenderer: params => {
          return '起始:' + params.data.deliveryBtm + '结束:' + params.data.deliveryEtm;
        }
      },
      { headerName: '运输运费（元）', field: 'cash', width: 100 },
      { headerName: '已支付运费（元）', field: 'orderMoney', width: 100 },
      {
        headerName: '安联订单审核', field: 'state', width: 120,
        editable: true,
        cellRenderer: this.renderer.editorSelect,
        cellEditor: 'agRichSelectCellEditor',
        cellClass: 'cell-editor',
        cellEditorParams: {
          cellRenderer: this.renderer.editorSelect,
          values: [
            { value: '未申请', code: 0 },
            { value: '待审核', code: 1 },
            { value: '已通过', code: 2 },
            { value: '已拒绝', code: 3 }
          ]
        }
      }
    ];
  }

  /**
   * 油卡表格项
   */
  getOilCardColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '油卡卡号', field: 'cardNum', width: 150 },
      { headerName: '手机号', field: 'driver.mobile', width: 120 },
      { headerName: '车牌号', field: 'car.plateNum', width: 120 },
      { headerName: '司机姓名', field: 'driver.name', width: 100 },
      { headerName: '油卡余额', field: 'balance', width: 120 },
      { headerName: '油卡类型', field: 'thirdCard.cardType.text', width: 120 },
      { headerName: '油卡提供方', field: 'oilCardType.text', width: 120 }
      // { headerName: '公司名称', field: 'companyName', width: 200 },
      // { headerName: '状态', field: 'state', width: 180, cellRenderer: this.renderer.oilCardState }
    ];
  }

  /**
   * 公司表格项
   */
  getCompanyColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '企业名称', field: 'name', width: 150 },
      { headerName: '联系人', field: 'contacts', width: 120 },
      { headerName: '联系电话', field: 'phone', width: 120 },
      { headerName: '纳税识别号', field: 'taxNumber', width: 180 },
      { headerName: '通信地址', field: 'addr', width: 200 },
      { headerName: '注册地址', field: 'bizLicenseAddr', width: 200 },
      { headerName: '物流园/城市', field: 'park', width: 150 }
    ];
  }

  /**
   * 油卡充值日志表格项
   */
  getOilCardRechargeLogColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '油卡充值编号', field: 'carId', width: 150 },
      { headerName: '油卡卡号', field: 'cardNum', width: 200 },
      { headerName: '充值金额', field: 'prepayment', width: 150 },
      { headerName: '操作人', field: '', width: 180 },
      { headerName: '充值时间', field: 'reclaimDatetime', width: 200 }
    ];
  }

  /**
   * 充值日志表格项
   */
  getRechargeLogColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '充值编号', field: 'offerOrderCode', width: 300 },
      { headerName: '公司名称', field: 'oppositeAccountName', width: 200 },
      { headerName: '手机号', field: 'userMobile', width: 150 },
      { headerName: '充值金额', field: 'paymentAmount', width: 120 },
      {
        headerName: '冲减标志', field: 'paymentAmount', width: 100,
        cellRenderer: (params) => {
          const flag = params.value > 0;
          return `<span style="
            color: ${flag ? 'green' : 'red'};
             border: 1px solid #cccccc;
              padding: 5px; border-radius: 50%;">${flag ? '充' : '减'}</span>`;
        }
      },
      { headerName: '操作人', field: 'payer', width: 120 },
      { headerName: '充值时间', field: 'paymentDate', width: 200, cellRenderer: this.renderer.dateFormat() }
    ];
  }

  /**
   * 开票日志表格项
   */
  getBillingLogColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '申请编号', field: 'applyCode', width: 300 },
      { headerName: '申请人', field: 'applyUsername', width: 120 },
      { headerName: '用户手机号', field: 'applyUserMobile', width: 150 },
      { headerName: '开票状态', field: 'statu', width: 120, cellRenderer: this.renderer.invoiceState },
      { headerName: '发票金额', field: 'money', width: 120 },
      { headerName: '操作人', field: 'operator', width: 120 },
      { headerName: '操作时间', field: 'operateTime', width: 200, cellRenderer: this.renderer.dateFormat() }
    ];
  }

  /**
   * 用户操作日志日志表格项
   */
  getLoginLogColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '日志类型', field: 'logType', width: 200, cellRenderer: this.renderer.rendererLogType },
      { headerName: '操作信息', field: 'operate', width: 300 },
      { headerName: '操作人姓名', field: 'createUser', width: 200 },
      { headerName: '操作时间', field: 'createTime', width: 200 },
      { headerName: '数据ID', field: 'dataId', width: 150 },
      { headerName: '操作人公司IP', field: 'ip', width: 200 },
      { headerName: '客户端', field: 'point', width: 200 },
      { headerName: '功能方法', field: 'method', width: 200 }
    ];
  }

  /**
   * 服务费类型表格项
   */
  getServiceFeeTypeColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '名称', field: 'name', width: 150 },
      { headerName: '默认价格', field: 'defaultFee', width: 120 },
      { headerName: '用途', field: 'purpose', width: 200 },
      { headerName: '备注', field: 'remark', width: 200 },
      { headerName: '创建人', field: 'createUserPo.userName', width: 120 },
      { headerName: '创建时间', field: 'createTime', width: 150, cellRenderer: this.renderer.dateFormat() },
      { headerName: '修改人', field: 'updateUserPo.userName', width: 120 },
      { headerName: '修改时间', field: 'updateTime', width: 150, cellRenderer: this.renderer.dateFormat() }
    ];
  }

  /**
   * 服务费总计表格项
   */
  getServiceFeeTotalColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '公司名称', field: 'companyName', width: 200 },
      { headerName: '收取金额', field: 'money', width: 120 },
      { headerName: '最后收取时间', field: 'lastDate', width: 150, cellRenderer: this.renderer.dateFormat() }
    ];
  }

  /**
   * 服务费群组表格项
   */
  getServiceFeeGroupColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '群组名称', field: 'name', width: 150 },
      { headerName: '服务费率', field: 'serviceRate', width: 100 },
      { headerName: '油卡费率', field: 'oilCardRate', width: 100 },
      { headerName: '预充油卡比例第一等', field: 'preOilFirst', width: 150 },
      { headerName: '预充油卡比例第二等', field: 'preOilSecond', width: 150 },
      { headerName: '预充油卡比例第三等', field: 'preOilThird', width: 150 },
      { headerName: '预充油卡比例第四等', field: 'preOilFourth', width: 150 },
      { headerName: '预充油卡比例第五等', field: 'preOilFifth', width: 150 },
      { headerName: '预充油卡比例第六等', field: 'preOilSixth', width: 150 },
      { headerName: '预充油卡比例第七等', field: 'preOilSeventh', width: 150 },
      { headerName: '创建人', field: 'createUserPo.userName', width: 120 },
      { headerName: '创建时间', field: 'createTime', width: 150, cellRenderer: this.renderer.dateFormat() },
      { headerName: '修改人', field: 'updateUserPo.userName', width: 120 },
      { headerName: '修改时间', field: 'updateTime', width: 150, cellRenderer: this.renderer.dateFormat() }
    ];
  }

  /**
   * 服务费表格项
   */
  getServiceFeeListColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '服务费类型名称', field: 'serviceFeeType.name', width: 150 },
      { headerName: '标准价格', field: 'serviceFeeType.defaultFee', width: 120 },
      { headerName: '实际价格', field: 'fee', width: 120 },
      { headerName: '创建人', field: 'createUserPo.userName', width: 120 },
      { headerName: '创建时间', field: 'createTime', width: 150, cellRenderer: this.renderer.dateFormat() },
      { headerName: '修改人', field: 'updateUserPo.userName', width: 120 },
      { headerName: '修改时间', field: 'updateTime', width: 150, cellRenderer: this.renderer.dateFormat() }
    ];
  }

  /**
   * 保单日志表格项
   */
  getPolicyLogColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '保单编号', field: 'insuranceId', width: 100 },
      { headerName: '是否发送至中交', field: 'syncZj', width: 130, cellRenderer: this.renderer.isBoolean },
      { headerName: '派车单号', field: 'carOrderCode', width: 350 },
      { headerName: '货物名称', field: 'cargoName', width: 100 },
      { headerName: '货物类型', field: 'cargoType', width: 130, cellRenderer: this.renderer.ZJCargoType },
      { headerName: '保额', field: 'totalInsuredAmount', width: 80 },
      { headerName: '保障起期', field: 'riskStartDate', width: 150, cellRenderer: this.renderer.dateFormat() },
      { headerName: '保障止期', field: 'riskEndDate', width: 150, cellRenderer: this.renderer.dateFormat() },
      // {
      //   headerName: '起运地址', field: 'startAddress', width: 180, cellRenderer: this.renderer.renderAddress('start')
      // },
      // {
      //   headerName: '停运地址', field: 'endAddress', width: 180, cellRenderer: this.renderer.renderAddress('end')
      // },
      {
        headerName: '起运地址', field: 'startAreaName', width: 180
      },
      {
        headerName: '停运地址', field: 'finishAreaName', width: 180
      },
      // { headerName: '车牌颜色', field: 'vc', width: 100, cellRenderer: this.renderer.vc },
      { headerName: '操作人', field: 'createUserPo.userName', width: 100 },
      { headerName: '操作时间', field: 'createTime', width: 150, cellRenderer: this.renderer.dateFormat() }
    ];
  }

  /**
   * 订单管理表格项
   */
  getOrderListColumns() {
    return [
      ...this.getBaseColumns,
      {
        headerName: '订单编号', field: 'carOrderCode', width: 310,
        cellRenderer: (params) => {
          return `<a>${params.data.carOrderCode}</a>`;
        }
      },
      { headerName: '订单状态', field: 'state', width: 120, cellRenderer: this.renderer.orderState },
      { headerName: '建单人', field: 'creator', width: 80 },
      { headerName: '订单类型', field: 'sync', width: 90, cellRenderer: this.renderer.syncType },
      { headerName: '货物名称', field: 'title', width: 100 },
      { headerName: '货物类型', field: 'cargoType', width: 120, cellRenderer: this.renderer.cargoType },
      { headerName: '货物分类', field: 'goodsPropertyTypeCode', width: 120, cellRenderer: this.renderer.freightType },
      { headerName: '货物重量(吨)', field: 'cargoNum', width: 110 },
      { headerName: '单位重量(千克)', field: 'unitWight', width: 130 },
      { headerName: '收货人', field: 'consignee', width: 100 },
      { headerName: '收货人电话', field: 'consigneePhoneNum', width: 130 },
      { headerName: '发货人', field: 'consigner', width: 100 },
      { headerName: '发单时间', field: 'fdsj', width: 160 },
      { headerName: '发单方式', field: 'fdfs', width: 100 },
      // { headerName: '货物所属行业', field: 'codename', width: 180 },
      { headerName: '司机姓名', field: 'name', width: 100 },
      { headerName: '司机电话', field: 'mobile', width: 140 },
      { headerName: '车牌号', field: 'plateNum', width: 120 },
      { headerName: '运费总额(元)', field: 'orderMoney', width: 120 },
      { headerName: '装车预付费(元)', field: 'prepayment', width: 120 },
      { headerName: '剩余费用(元)', field: 'cash', width: 120 },
      { headerName: '提货最晚时间', field: 'pickupEndTime', width: 160 },
      { headerName: '预计卸货时间', field: 'unloadEndTime', width: 160 },
      { headerName: '起始地点', field: 'cargoAddress', width: 240 },
      { headerName: '起始地详细地址', field: 'cargoAddrDetail', width: 80 },
      { headerName: '结束地点', field: 'deliveryAddress', width: 250 },
      { headerName: '结束地详细地址', field: 'deliveryAddrDetail', width: 80 },

    ];
  }

  /**
   * 消息中心表格项
   */
  getMessageListColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '消息编号', field: 'messageId', width: 100 },
      { headerName: '消息状态', field: 'state', width: 120, cellRenderer: this.renderer.messageState },
      { headerName: '消息类型', field: 'messageType.title', width: 150 },
      { headerName: '消息内容', field: 'messageType.desc', width: 300 },
      { headerName: '创建时间', field: 'createTime', width: 150, cellRenderer: this.renderer.dateFormat() },
      { headerName: '推送时间', field: 'pushTime', width: 150, cellRenderer: this.renderer.dateFormat() }
    ];
  }

  /**
   * 天津订单表格项
   */
  getCarOrderTaxColumns() {
    return [
      ...this.getBaseColumns,
      // { headerName: '对应服务方唯一标识', field: 'fwfuuid', width: 120 },
      { headerName: '被代征人姓名', field: 'bdzdwnsrmc', width: 120, },
      { headerName: '被代征人身份证号', field: 'bdzdwnsrsbh', width: 120 },
      { headerName: '国家或地区', field: 'gjhdq', width: 120, cellRenderer: this.renderer.dateFormat() },
      { headerName: '证件类型', field: 'zjlx', width: 120 },
      { headerName: '证件号码', field: 'zjhm', width: 120 },
      { headerName: '所属行业', field: 'sshy', width: 120, cellRenderer: this.renderer.dateFormat() },
      { headerName: '征收项目', field: 'zsxm', width: 120 },
      { headerName: '征收品目', field: 'zspm', width: 120, },
      { headerName: '征收子目', field: 'zszm', width: 120, },
      { headerName: '税款所属期起', field: 'skssqq', width: 120, },
      { headerName: '税款所属期止', field: 'skssqz', width: 120 },
      { headerName: '计税依据数额界限', field: 'jsyj', width: 120 },
      { headerName: '税率或税额', field: 'slhse', width: 120 },
      { headerName: '应纳税额', field: 'ynse', width: 120 },
      { headerName: '减免税额', field: 'jmse', width: 120 },
      { headerName: '已缴税额', field: 'yjse', width: 120 },
      { headerName: '应代征税额', field: 'ydzse', width: 120 },
      { headerName: '已代征税额', field: 'sdzse', width: 120 },
      { headerName: '减免性质', field: 'jmxz', width: 120 },
    ];
  }

  /**
   * 个税表格项
   */
  getPersonTaxColumns() {
    return [
      ...this.getBaseColumns,
      // { headerName: '对应服务方唯一标识', field: 'fwfuuid', width: 120 },
      { headerName: '纳税人识别号', field: 'nsrsbh', width: 200 },
      { headerName: '纳税人名称', field: 'nsrmc', width: 200 },
      // { headerName: '行业', field: 'hy', width: 120 },
      // { headerName: '行政区划', field: 'xzqh', width: 120 },
      // { headerName: '身份证件类型（自然人）', field: 'sfzjlx', width: 120 },
      // { headerName: '国籍（地区）', field: 'gjdq', width: 120 },
      // { headerName: '生产经营地行政区划', field: 'scjydxzqh', width: 120 },
      { headerName: '街道乡镇', field: 'jdxz', width: 200 },
      { headerName: '税务机关', field: 'swjg', width: 260 },
      { headerName: '身份证件号码', field: 'sfzjhm', width: 180 },
      { headerName: '姓名', field: 'xm', width: 80 },
      { headerName: '联系电话', field: 'lxdh', width: 120 },
      { headerName: '申报日期', field: 'sbrq', width: 180 },
      { headerName: '税款所属期起', field: 'skssqq', width: 120 },
      { headerName: '税款所属期止', field: 'skssqz', width: 120 },
      { headerName: '应税收入', field: 'yssr', width: 120 },
      { headerName: '应税所得率', field: 'yssdl', width: 120 },
      { headerName: '计税依据', field: 'jsyj', width: 120 },
      { headerName: '税率', field: 'sl', width: 100 },
      { headerName: '速算扣除数', field: 'sskcs', width: 120 },
      { headerName: '应纳税额', field: 'ynse', width: 100 },
      { headerName: '累计已缴税额', field: 'ljyjse', width: 120 },
      { headerName: '本期应补退税额', field: 'bqybtse', width: 130 },
      { headerName: '申报状态', field: 'sync.text', width: 120 }
    ];
  }

  /**
   * 个税汇总表格项
   */
  getPersonTaxTotalColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '税款所属期起', field: 'skssqq', width: 120 },
      { headerName: '税款所属期止', field: 'skssqz', width: 120 },
      { headerName: '申报日期', field: 'sbrq', width: 120 },
      { headerName: '扣缴义务人识别号', field: 'nsrsbh', width: 180 },
      { headerName: '扣缴义务人名称', field: 'nsrmc', width: 220 },
      { headerName: '税率', field: 'sl', width: 120 },
      { headerName: '申报人数', field: 'sbrs', width: 100 },
      { headerName: '应税收入', field: 'yssr', width: 100 },
      { headerName: '应纳税额', field: 'ynse', width: 100 },
      { headerName: '累计已缴纳税额', field: 'ljyjnse', width: 130 },
      { headerName: '本期应补退税额', field: 'bqybtse', width: 130 },
      { headerName: '申报状态', field: 'sync.text', width: 120 }
    ];
  }

  /**
   * 油卡使用日志表格项
   */
  getOilCardUseLogColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '油卡卡号', field: 'cardNo', width: 130 },
      { headerName: '司机名', field: 'truckerName', width: 100 },
      { headerName: '司机电话', field: 'truckerMobile', width: 130 },
      { headerName: '订单编号', field: 'orderSn', width: 220 },
      { headerName: '型号', field: 'skuName', width: 150 },
      { headerName: '价格', field: 'price', width: 110 },
      { headerName: '支付金额', field: 'paymentAmount', width: 110 },
      { headerName: '支付状态', field: 'paymentStatus', width: 100, cellRenderer: this.renderer.paymentState },
      { headerName: '城市', field: 'cityName', width: 150 },
      { headerName: '站点名称', field: 'stationName', width: 180 }
    ];
  }

  /**
   * 行业表格项
   */
  getIndustryColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '行业编号', field: 'code', width: 100 },
      { headerName: '行业名称', field: 'codename', width: 200 }
    ];
  }

  /**
   * 服务方税费表格项
   */
  getOtherTaxColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '被代征人姓名', field: 'bdzdwnsrmc', width: 120, },
      { headerName: '被代征人身份证号', field: 'bdzdwnsrsbh', width: 200 },
      // { headerName: '国家或地区', field: 'gjhdq', width: 120 },
      // { headerName: '证件类型', field: 'zjlx', width: 120 },
      // { headerName: '证件号码', field: 'zjhm', width: 120 },
      // { headerName: '所属行业', field: 'sshy', width: 120 },
      // { headerName: '征收项目', field: 'zsxm', width: 120 },
      // { headerName: '征收品目', field: 'zspm', width: 120, },
      // { headerName: '征收子目', field: 'zszm', width: 120, },
      // { headerName: '计税依据数额界限', field: 'jsyj', width: 120 },
      // { headerName: '减免性质', field: 'jmxz', width: 120 },
      { headerName: '税款所属期起', field: 'skssqq', width: 120, },
      { headerName: '税款所属期止', field: 'skssqz', width: 120 },
      { headerName: '税率或税额', field: 'slhse', width: 120 },
      { headerName: '应纳税额', field: 'ynse', width: 120 },
      { headerName: '减免税额', field: 'jmse', width: 120 },
      { headerName: '已缴税额', field: 'yjse', width: 120 },
      { headerName: '应代征税额', field: 'ydzse', width: 120 },
      { headerName: '已代征税额', field: 'sdzse', width: 120 },
      { headerName: '申报状态', field: 'sync.text', width: 120 }
    ];
  }
  /**
    * 销项发票表格项
    */
  getOutputInvoiceColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '发票申请编号', field: 'applyCode', width: 300, },
      { headerName: '运单号', field: 'orderCode', width: 200 },
      { headerName: '发票代码', field: 'invoiceCode', width: 100, },
      { headerName: '发票号码', field: 'invoiceNo', width: 120 },
      { headerName: '公司名称', field: 'draweeName', width: 220 },
      { headerName: '合计金额', field: 'invoiceMoney', width: 100 },
      { headerName: '开票日期', field: 'invoiceDateTime', width: 160, cellRenderer: this.renderer.dateFormat() },
      { headerName: '所属月份', field: 'invoiceMonth', width: 120 },
      { headerName: '操作人', field: 'applyName', width: 100 },
      { headerName: '上传时间', field: 'pushDateTime', width: 160, cellRenderer: this.renderer.dateFormat() },
    ];
  }

  /**
 * 电子运单表格项
 */
  getEleTrafficColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: 'ID', field: 'electronicOrderId', width: 80 },
      { headerName: '单号', field: 'shippingNoteNumber', width: 220 },
      { headerName: '上传时间', field: 'pushTime', width: 200 },
      { headerName: '操作人', field: 'createUser', width: 180 },
      { headerName: '订单类型', field: 'type', width: 100, cellRenderer: this.renderer.areaType },
      { headerName: '是否同步', field: 'sync', width: 100, cellRenderer: this.renderer.isBoolean }
    ];
  }

  /**
* 资金流水单表格项
*/
  getCapFlowColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: 'ID', field: 'moneyOrderId', width: 80 },
      { headerName: '单证号', field: 'documentNumber', width: 320 },
      { headerName: '上传时间', field: 'pushTime', width: 200 },
      { headerName: '托运单号', field: 'shippingNoteNumber', width: 180 },
      { headerName: '操作人', field: 'createUser', width: 100 },
      { headerName: '订单类型', field: 'type', width: 100, cellRenderer: this.renderer.areaType },
      { headerName: '是否同步', field: 'sync', width: 100, cellRenderer: this.renderer.isBoolean }
    ];
  }

  /**
* 车辆信息单表格项
*/
  getCarInfoColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '车牌号', field: 'vehicleNumber', width: 100 },
      { headerName: '所有人', field: 'owner', width: 240 },
      { headerName: '道路运输许可证号', field: 'roadTransportCertificateNumber', width: 160 },
      { headerName: '操作人', field: 'createUser', width: 100 },
      { headerName: '上传时间', field: 'pushTime', width: 180 },
      { headerName: '是否同步', field: 'sync', width: 100, cellRenderer: this.renderer.isBoolean }
    ];
  }

  /**
  * 驾驶员信息单表格项
  */
  getDriverInfoColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '姓名', field: 'driverName', width: 100 },
      { headerName: '身份证号', field: 'drivingLicense', width: 180 },
      { headerName: '手机号', field: 'telephone', width: 140 },
      { headerName: '操作人', field: 'createUser', width: 100 },
      { headerName: '上传时间', field: 'pushTime', width: 180 },
      { headerName: '是否同步', field: 'sync', width: 100, cellRenderer: this.renderer.isBoolean }
    ];
  }

  /**
   * 已支付订单表格项
   */
  getPayedOrderColumns() {
    return [
      ...this.getBaseColumns,
      {
        headerName: '单号', field: 'orderCode', width: 320,
        cellRenderer: (params) => {
          return `<a>${params.data.orderCode}</a>`;
        }
      },
      { headerName: '车牌号', field: 'plateNum', width: 100 },
      { headerName: '始发地', field: 'cargoAddress', width: 220 },
      { headerName: '目的地', field: 'deliveryAddress', width: 220 },
      { headerName: '金额(元)', field: 'paymentAmount', width: 120 },
      { headerName: '是否上传', field: 'syncTj', width: 100, cellRenderer: this.renderer.isSync },
      { headerName: '操作人', field: 'syncTjOperator', width: 100 },
      { headerName: '操作时间', field: 'syncTjOperateTime', width: 180 }
    ];
  }

  getCompanyVipColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '会员名称', field: 'clientName', width: 300 },
      { headerName: '证件号码', field: 'idCode', width: 180 },
      { headerName: '会员账号', field: 'fundAcc', width: 180 },
      { headerName: '账户状态', field: 'status', width: 90, cellRenderer: this.renderer.accountStatus },
      { headerName: '会员类型', field: 'clientType', width: 120, cellRenderer: this.renderer.userType },
      { headerName: '余额', field: 'balance', width: 80 },
      { headerName: '冻结余额', field: 'frozenBalance', width: 100 },
      // { headerName: '注册时间', field: 'openDate', width: 140 },
      // { headerName: '最后一次修改时间', field: 'lastEditTime', width: 160 }
    ];
  }

  getMineBanksColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '名称', field: 'accountName', width: 80 },
      { headerName: '证件号', field: 'accountId', width: 180 },
      { headerName: '发卡行', field: 'bankName', width: 120 },
      { headerName: '卡号', field: 'cardCode', width: 180 },
      { headerName: '绑定状态', field: 'bindFlag', width: 160, cellRenderer: this.renderer.bindFlag },
      { headerName: '开户所在地', field: 'cityName', width: 160 },
    ];
  }

  getPayOrderColumns() {
    return [
      ...this.getBaseColumns,
      // { headerName: '支付编号', field: 'offerOrderCode', width: 120 },
      // { headerName: '关联派车单号', field: 'secuOrderId', width: 120 },
      // { headerName: '收款人', field: 'oppositeAccountName', width: 120 },
      // { headerName: '金额', field: 'paymentAmount', width: 100},
      // { headerName: '交易类型', field: 'offerType', width: 160, cellRenderer: this.renderer.userType },
      // { headerName: '发生时间', field: 'applicationDate', width: 160 },
      // { headerName: '交易状态', field: 'offerState', width: 160 , cellRenderer: this.renderer.isSync },
      // { headerName: '操作人', field: 'proposer', width: 120 },
      { headerName: '订单号', field: 'orderId', width: 290 },
      { headerName: '交易类型', field: 'businCode', width: 100, cellRenderer: this.renderer.businType },
      { headerName: '交易编码', field: 'transCode', width: 120 },
      { headerName: '付款子账簿编号', field: 'payFundAcc', width: 160 },
      { headerName: '交易日期', field: 'transDate', width: 160 },
      { headerName: '收款子账簿编号', field: 'targFundAcc', width: 160 },
      { headerName: '交易金额', field: 'amt', width: 100 },
      { headerName: '关联派车单号', field: 'carOrderCode', width: 120 },
      { headerName: '交易状态', field: 'status', width: 100, cellRenderer: this.renderer.businStatus },
      { headerName: '业务流水号', field: 'bussNo', width: 180 },
      { headerName: '银行卡号', field: 'bankAcc', width: 120 },
      // { headerName: '冲正', field: 'cz', width: 100 },
      // { headerName: '退单', field: 'td', width: 100 },
      // { headerName: '是否线下操作', field: 'offLine', width: 120 },
      { headerName: '交易备注', field: 'remark', width: 140 },
    ]
  }

  getOffLineColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '子账簿编号', field: 'account', width: 120 },
      { headerName: '订单号', field: 'secuOrderId', width: 120 },
      { headerName: '金额', field: 'amt', width: 120 },
      { headerName: '交易时间', field: 'transDate', width: 100 },
      { headerName: '状态', field: 'status', width: 160, cellRenderer: this.renderer.isSync },
      { headerName: '错误信息', field: 'errMsg', width: 160 }
    ]
  }

  getFeeInquiryColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '商户编号', field: 'secuNo', width: 120 },
      { headerName: '商户子账簿名称', field: 'secuName', width: 200 },
      { headerName: '费用名称', field: 'feeName', width: 120 },
      { headerName: '总费用', field: 'totFee', width: 100 },
      { headerName: '已结转费用', field: 'settleFee', width: 160 },
      { headerName: '待结转费用', field: 'fee', width: 160 },
      { headerName: '总银行代理费用', field: 'totBankFee', width: 200 },
      { headerName: '已结算费用', field: 'settleBankFee', width: 160 },
      { headerName: '待结算费用', field: 'bankFee', width: 160 }
    ]
  }

  getCapFlowDetailColumns() {
    return [
      ...this.getBaseColumns,
      // { headerName: '商户订单号', field: 'serialNo', width: 220 },
      { headerName: '商户订单号', field: 'secuOrderId', width: 300 },
      { headerName: '对方账号', field: 'otherAcc', width: 160 },
      { headerName: '会员名', field: 'clientName', width: 120 },
      { headerName: '付款账号', field: 'fundAcc', width: 160 },
      { headerName: '交易后余额', field: 'postBala', width: 120 },
      { headerName: '交易项目', field: 'prdName', width: 120 },
      {
        headerName: '交易日期时间', field: 'transDate', width: 200,
        cellRenderer: (params) => {
          return `<span>${this.util.dateFormat(params.value, 'YYYY-MM-DD') + ' ' + this.util.getFormatTime(params.data.transTime)}</span>`;
        }
      },
      { headerName: '金额', field: 'amt', width: 100 },
      {
        headerName: '变动标识', field: 'chgFlag', width: 160, cellRenderer: this.renderer.markType
        // cellRenderer: (params) => {
        //   const flag = params.value > 0;
        //   return `<span style="
        //     color: ${flag ? 'green' : 'red'};
        //      border: 1px solid #cccccc;
        //       padding: 5px; border-radius: 50%;">${flag ? '充' : '减'}</span>`;
        // }
      },
      { headerName: '业务类型', field: 'businCode', width: 160, cellRenderer: this.renderer.businType },
      { headerName: '操作人', field: 'fromFlag', width: 160 }
    ]
  }

  getMerchantCapFlowDetailColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '子账簿编号', field: 'bankAcc', width: 180 },
      { headerName: '子账簿编号名称', field: 'accName', width: 300 },
      { headerName: '子账簿属性名称', field: 'accAttrName', width: 160 },
      { headerName: '商户订单号', field: 'secuOrderId', width: 300 },
      { headerName: '业务名称', field: 'businName', width: 140 },
      { headerName: '金额', field: 'amt', width: 100 },
      { headerName: '交易后余额', field: 'postBala', width: 120 },
      { headerName: '变动标识', field: 'chgFlagName', width: 100 },
      { headerName: '交易日期', field: 'transDate', width: 100 },
      { headerName: '业务发起人', field: 'fromFlag', width: 120 }
    ]
  }

  getAccsColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '用户编号', field: 'userNum', width: 180 },
      { headerName: '真实名称', field: 'name', width: 240 },
      { headerName: '手机号', field: 'mobilePhone', width: 120 },
      { headerName: '用户类型', field: 'type', width: 100, cellRenderer: this.renderer.signAccType },
      { headerName: '颁发者', field: 'issuer', width: 200 },
      { headerName: '证件号码', field: 'idCardNum', width: 180 },
      { headerName: '证件类型', field: 'cardType', width: 140, cellRenderer: this.renderer.signCardType },
      // { headerName: '创建人', field: 'createUserName', width: 100},
      // { headerName: '创建时间', field: 'createTime', width: 120},
      // { headerName: '操作人', field: 'updateUserName', width: 100 },
      // { headerName: '操作时间', field: 'updateTime', width: 100 }
    ]
  }

  getSignsColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '印章签名序列号', field: 'signNumber', width: 160 },
      { headerName: '印章名', field: 'signName', width: 100 },
      { headerName: '印章单位名称', field: 'unitName', width: 260 },
      { headerName: '签章类型', field: 'signType', width: 120, cellRenderer: this.renderer.signType },
      // { headerName: '签章图片', field: 'signImg', width: 100 },
      { headerName: '用户账号', field: 'userNum', width: 180 },
      { headerName: '签章所属用户名', field: 'userName', width: 300 },
      { headerName: '签章所属用户手机号', field: 'mobilePhone', width: 160 },
      // { headerName: '签章用户类型', field: 'signCustomer', width: 120, cellRenderer: this.renderer.signAccType },
      // { headerName: '合同发起人', field: 'contractSendSigner', width: 100 },
      // { headerName: '合同签署人', field: 'otherSigner', width: 100 },
      // { headerName: '创建人', field: 'createUserName', width: 100},
      // { headerName: '创建时间', field: 'createTime', width: 120},
      // { headerName: '操作人', field: 'updateUserName', width: 100 },
      // { headerName: '操作时间', field: 'updateTime', width: 100 }
    ]
  }

  getConsColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '合同编号', field: 'contractNum', width: 300 },
      { headerName: '合同名', field: 'contractName', width: 180 },
      // { headerName: '合同发起人账号', field: 'contractSendSigner', width: 160 },
      // { headerName: '签署人账号', field: 'otherSigner', width: 300 },
      // { headerName: '合同文件', field: 'contractFile', width: 140 },//pdf
      // { headerName: '合同图片', field: 'contractImg', width: 100},//img
      { headerName: '合同签署状态', field: 'signState', width: 120, cellRenderer: this.renderer.signState },
      { headerName: '合同状态', field: 'state', width: 100, cellRenderer: this.renderer.conState },
      { headerName: '剩余天数', field: 'contractInfo.leftValDays', width: 160 },
      { headerName: '签署日期', field: 'otherSignerSignDate', width: 160, cellRenderer: this.renderer.dateFormat() },
      // { headerName: '模板编号', field: 'templateNumber', width: 300 },
      // { headerName: '模板变量值', field: 'replaceVal', width: 120 },//变量
      // { headerName: '创建人', field: 'createUserName', width: 100},
      // { headerName: '创建时间', field: 'createTime', width: 120},
      // { headerName: '操作人', field: 'updateUserName', width: 100 },
      // { headerName: '操作时间', field: 'updateTime', width: 100 }
    ]
  }

  getTempsColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '模板编号', field: 'templateNumber', width: 300 },
      { headerName: '模板名', field: 'templateName', width: 150 },
      // { headerName: '印章名', field: 'signName', width: 160 },
      // { headerName: '签章位置信息', field: 'signPosition', width: 300 },
      // { headerName: '模板内容', field: 'html', width: 140 },
      // { headerName: '创建人', field: 'createUserName', width: 100},
      // { headerName: '创建时间', field: 'createTime', width: 120},
      // { headerName: '操作人', field: 'updateUserName', width: 100 },
      // { headerName: '操作时间', field: 'updateTime', width: 100 }
    ]
  }

  getPartnerColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '编号', field: 'fbfuuid', width: 300 },
      { headerName: '公司名称', field: 'name', width: 220 },
      { headerName: '天津', field: 'tjSync;', width: 100, cellRenderer: this.renderer.syncState },
      { headerName: '天津同步时间', field: 'tjDate;', width: 160 },
      { headerName: '金昌', field: 'jcSync', width: 100, cellRenderer: this.renderer.syncState },
      { headerName: '金昌同步时间', field: 'jcDate;', width: 160 },
      { headerName: '上传时间', field: 'ptzcsj', width: 160 },
      { headerName: '操作人', field: 'connector', width: 220 },
    ]
  }

  getTaxOfficeColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '税务机关代码', field: 'dm', width: 120 },
      { headerName: '税务机关名称', field: 'mc', width: 240 },
      { headerName: '税务机关简称', field: 'jc', width: 240 }
    ]
  }

  getUnionColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '会员名称', field: 'driverName', width: 200 },
      { headerName: '手机号', field: 'phone', width: 200 },
      { headerName: '是否为会员', field: 'joinTradeUnion', width: 160, cellRenderer: this.renderer.isVIP },
      { headerName: '身份证号', field: 'idNum', width: 220 },
      { headerName: '会员工会所在地', field: 'address', width: 160 },
    ]
  }

  getJimiColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '设备编号', field: 'imei', width: 200 },
      { headerName: '关联车牌', field: 'plateNum', width: 200 },
      { headerName: '操作时间', field: 'updateTime', width: 160 },
      { headerName: '操作人', field: 'updateAuthor', width: 220 }
    ]
  }

  getJimiMsgColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '设备编号', field: 'code', width: 200 },
      { headerName: '消息内容', field: 'data', width: 200, cellRenderer: this.renderer.msgData },
      { headerName: '接收时间', field: 'createTime', width: 160 },
    ]
  }

  getDeclaresColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '编号', field: 'contractNum', width: 300 },
      { headerName: '声明名', field: 'contractName', width: 180 },
      { headerName: '声明签署状态', field: 'signState', width: 120, cellRenderer: this.renderer.signState },
      { headerName: '声明状态', field: 'state', width: 100, cellRenderer: this.renderer.conState },
      // { headerName: '剩余天数', field: 'contractInfo.leftValDays', width: 160 },
      { headerName: '签署日期', field: 'otherSignerSignDate', width: 160, cellRenderer: this.renderer.dateFormat() },
    ]
  }

  getEnterpriseDataColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '企业名称', field: 'entName', width: 200 },
      { headerName: '法人代表姓名', field: 'legalerName', width: 180 },
      { headerName: '同步状态', field: 'syncDy', width: 120, cellRenderer: this.renderer.isSyncData },
      { headerName: '同步时间', field: 'pushTime', width: 200 },
      { headerName: '同步结果', field: 'pushResult', width: 120 },
      { headerName: '操作人', field: 'opertionName', width: 120 },
    ]
  }

  getVehicleDataColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '车牌号码', field: 'plateNum', width: 100 },
      { headerName: '所有人', field: 'owner', width: 250 },
      { headerName: '车辆类型', field: 'carType', width: 150 },
      // { headerName: '是否定位', field: 'location', width: 100, cellRenderer: this.renderer.isBoolean },
      // { headerName: '联系方式', field: 'driverMobile', width: 120 },
      // { headerName: '状态', field: 'verifyState', width: 100, cellRenderer: this.renderer.verifyState },
      // { headerName: '绑定司机', field: 'driverName', width: 150 },
      // { headerName: '承运车辆品牌', field: 'cypp', width: 150 }
      { headerName: '同步状态', field: 'syncDy', width: 120, cellRenderer: this.renderer.isSyncData },
      { headerName: '同步时间', field: 'dyPushTime', width: 200 },
      { headerName: '同步结果', field: 'dyPushResult', width: 120 },
      { headerName: '操作人', field: 'dyOpertionName', width: 120 },
    ];
  }
  getDriverDataColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '姓名', field: 'name', width: 100 },
      { headerName: '身份证号码', field: 'idNum', width: 180 },
      { headerName: '手机号', field: 'mobile', width: 120 },
      { headerName: '驾照类型', field: 'licenseType', width: 100 },
      { headerName: '驾照号码', field: 'driverLicence', width: 180 },
      { headerName: '同步状态', field: 'syncDy', width: 120, cellRenderer: this.renderer.isSyncData },
      { headerName: '同步时间', field: 'dyPushTime', width: 200 },
      { headerName: '同步结果', field: 'dyPushResult', width: 120 },
      { headerName: '操作人', field: 'dyOpertionName', width: 120 },
      // { headerName: '驾驶证获取日期', field: 'licenseFirstDetDate', width: 130, cellRenderer: this.renderer.dateFormat('YYYY-MM-DD') },
      // { headerName: '纳税人姓名', field: 'taxpayerName', width: 120 },
      // { headerName: '纳税人身份证号码', field: 'taxpayerIdentity', width: 180 },
      // { headerName: '纳税人开户行', field: 'bankName', width: 120 },
      // { headerName: '纳税人银行卡号', field: 'bankAccout', width: 180 },

    ];
  }
  getOwnerDataColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '编号', field: 'fbfuuid', width: 300 },
      { headerName: '公司名称', field: 'name', width: 220 },
      { headerName: '同步状态', field: 'syncDy', width: 120, cellRenderer: this.renderer.isSyncData },
      { headerName: '同步时间', field: 'dyPushTime', width: 200 },
      { headerName: '同步结果', field: 'dyPushResult', width: 120 },
      { headerName: '操作人', field: 'dyOpertionName', width: 120 },
      // { headerName: '天津', field: 'tjSync;', width: 100, cellRenderer: this.renderer.syncState },
      // { headerName: '天津同步时间', field: 'tjDate;', width: 160 },
      // { headerName: '金昌', field: 'jcSync', width: 100, cellRenderer: this.renderer.syncState },
      // { headerName: '金昌同步时间', field: 'jcDate;', width: 160 },
      // { headerName: '上传时间', field: 'ptzcsj', width: 160 },
      // { headerName: '操作人', field: 'connector', width: 220 },
    ]
  }
  getWaybillDataColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '订单编号', field: 'orderCode', width: 300 },
      { headerName: '发货地址', field: 'cargoAddress', width: 220 },
      { headerName: '收货地址', field: 'deliveryAddress', width: 220 },
      { headerName: '运费总额', field: 'orderMoney', width: 120 },
      { headerName: '同步状态', field: 'syncDy', width: 120, cellRenderer: this.renderer.isSyncData },
      { headerName: '同步时间', field: 'dyPushTime', width: 200 },
      { headerName: '同步结果', field: 'dyPushResult', width: 120 },
      { headerName: '操作人', field: 'dyOpertionName', width: 120 },
      // { headerName: '天津', field: 'tjSync;', width: 100, cellRenderer: this.renderer.syncState },
      // { headerName: '天津同步时间', field: 'tjDate;', width: 160 },
      // { headerName: '金昌', field: 'jcSync', width: 100, cellRenderer: this.renderer.syncState },
      // { headerName: '金昌同步时间', field: 'jcDate;', width: 160 },
      // { headerName: '上传时间', field: 'ptzcsj', width: 160 },
      // { headerName: '操作人', field: 'connector', width: 220 },
    ]
  }
  getOrderDataColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '订单编号', field: 'orderCode', width: 300 },
      // { headerName: '订单状态', field: 'orderState', width: 220, cellRenderer: this.renderer.orderState },
      // { headerName: '建单人', field: 'creator', width: 220 },
      { headerName: '订单类型', field: 'sync', width: 90, cellRenderer: this.renderer.syncType },
      { headerName: '货物名称', field: 'title', width: 120 },
      { headerName: '发货地址', field: 'cargoAddress', width: 220 },
      { headerName: '收货地址', field: 'deliveryAddress', width: 220 },
      { headerName: '运费总额', field: 'totalCharge', width: 120 },
      { headerName: '同步状态', field: 'syncDy', width: 120, cellRenderer: this.renderer.isSyncData },
      { headerName: '同步时间', field: 'dyPushTime', width: 200 },
      { headerName: '同步结果', field: 'dyPushResult', width: 120 },
      { headerName: '操作人', field: 'dyOpertionName', width: 120 },
      // { headerName: '天津', field: 'tjSync;', width: 100, cellRenderer: this.renderer.syncState },
      // { headerName: '天津同步时间', field: 'tjDate;', width: 160 },
      // { headerName: '金昌', field: 'jcSync', width: 100, cellRenderer: this.renderer.syncState },
      // { headerName: '金昌同步时间', field: 'jcDate;', width: 160 },
      // { headerName: '上传时间', field: 'ptzcsj', width: 160 },
      // { headerName: '操作人', field: 'connector', width: 220 },
    ]
  }
  getFundDataColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '运单号', field: 'shippingNoteNumber', width: 300 },
      { headerName: '经营者名称', field: 'operatorName', width: 220 },
      { headerName: '实际承运人名称', field: 'carrier', width: 150 },
      { headerName: '总金额', field: 'totalMonetaryAmount', width: 120 },
      { headerName: '同步状态', field: 'syncDy', width: 120, cellRenderer: this.renderer.isSyncData },
      { headerName: '上传时间', field: 'sendToProDateTime', width: 200 },
      { headerName: '同步结果', field: 'dyPushResult', width: 120 },
      { headerName: '操作人', field: 'dyOpertionName', width: 120 },
      // { headerName: '天津', field: 'tjSync;', width: 100, cellRenderer: this.renderer.syncState },
      // { headerName: '天津同步时间', field: 'tjDate;', width: 160 },
      // { headerName: '金昌', field: 'jcSync', width: 100, cellRenderer: this.renderer.syncState },
      // { headerName: '金昌同步时间', field: 'jcDate;', width: 160 },
      // { headerName: '上传时间', field: 'ptzcsj', width: 160 },
      // { headerName: '操作人', field: 'connector', width: 220 },
    ]
  }
  getTracingpointDataColumns() {
    return [
      ...this.getBaseColumns,
      { headerName: '运单号', field: 'shippingNoteNumber', width: 300 },
      { headerName: '车牌号', field: 'vehicleNumber', width: 100 },
      { headerName: '开始时间', field: 'qryBtm', width: 150 },
      { headerName: '结束时间', field: 'qryEtm', width: 150 },
      { headerName: '同步状态', field: 'syncDy', width: 120, cellRenderer: this.renderer.isSyncData },
      { headerName: '同步时间', field: 'dyPushTime', width: 200 },
      { headerName: '同步结果', field: 'dyPushResult', width: 120 },
      { headerName: '操作人', field: 'dyOpertionName', width: 120 },
    ]
  }
}
