import { Injectable } from '@angular/core';
import { UtilService } from '@core/service/util.service';
import { messageState, ZJCargoType, vc, paymentState, freightType, syncType, areaType, workType, markType, businCode, bindFlag, signCardType, signAccType, signState, cargoType, syncState, isVIP, logType } from '@core/config/config';

@Injectable({
  providedIn: 'root'
})
export class RendererService {

  constructor(
    private util: UtilService
  ) { }

  isBoolean = (params) => {
    return params.value ? '是' : '否';
  }

  isSync = (params) => {
    return params.value === 1 ? '是' : '否';
  }

  isSyncData = (params) => {
    return params.value === true ? '已同步' : '未同步';
  }

  userType = (params) => {
    switch (params.value) {
      case '0':
        return '机构';
      case '1':
        return '个人';
      case '3':
        return '费用账户平台';
      case '2':
        return '平台';
    }
  }

  conState = (params) => {
    switch (params.value) {
      case 0:
        return '正常';
      case 1:
        return '撤销';
      case 2:
        return '退回';
      case 3:
        return '作废';
    }
  }

  signType = (params) => {
    switch (params.value) {
      case 1:
        return '签名';
      case 2:
        return '印章';
    }
  }


  accountStatus = (params) => {
    switch (params.value) {
      case '0':
        return '正常';
      case '1':
        return '冻结';
      case '2':
        return '关闭子账簿';
    }
  }

  businStatus = (params) => {
    switch (params.value) {
      case 'S':
        return '成功';
      case 'E':
        return '失败';
      case 'C':
        return '平台待确认';
    }
  }

  verifyState = (params) => {
    switch (params.value) {
      case 0:
        return '未审核';
      case 1:
        return '审核通过';
      case 2:
        return '审核中';
      case 3:
        return '审核未通过';
    }
  }

  userStatus = (params) => {
    switch (params.value) {
      case 1:
        return '正常';
      case 2:
        return '锁定';
      case 11:
        return '待审批';
      case 12:
        return '驳回';
      case 51:
        return '协议升级';
    }
  }

  editorSelect = (params) => {
    if (!!params.value) {
      switch (params.value.code) {
        case 0:
          return '未申请';
        case 1:
          return '待审核';
        case 2:
          return '已通过';
        case 3:
          return '已拒绝';
      }
    }
  }

  invoiceState = (params) => {
    switch (params.value) {
      case 0:
        return '已申请';
      case 1:
        return '开票中';
      case 2:
        return '申请成功';
      case 3:
        return '已拒绝';
      case 4:
        return '已生成销项发票';
      case 5:
        return '发票作废'
    }
  }

  oilCardState = (params) => {
    switch (params.value) {
      case 1:
        return '绑定';
      case 2:
        return '未绑定';
      case 3:
        return '丢失';
      case 4:
        return '回收';
    }
  }

  roleType = (params) => {
    switch (params.value) {
      case 1:
        return '管理员';
      case 2:
        return '内置';
      case 3:
        return '自定义(一般)';
      case 11:
        return '维护';
    }
  }

  orderState = (params) => {
    switch (params.value) {
      case 0:
        return '未发货';
      case 1:
        return '物流公司提货';
      case 2:
        return '司机提货';
      case 3:
        return '司机卸货';
      case 4:
        return '司机签收';
      case 5:
        return '司机回单';
      case 6:
        return '物流公司签收';
    }
  }

  // orderType = (params) => {
  //   const FDorderType = orderType.filter(item => item.code === params.value);
  //   return FDorderType[0].value;
  // }

  // industryType = (params) => {
  //   const HYindustryType = industryType.filter(item => item.code === params.value);
  //   return HYindustryType[0].value;
  // }

  // zxType = (params) => {
  //   const ZXType = zxType.filter(item => item.code === params.value);
  //   return ZXType[0].value;
  // }

  // payType = (params) => {
  //   const ZFpayType = payType.filter(item => item.code === params.value);
  //   return ZFpayType[0].value;
  // }

  ZJCargoType = (params) => {
    const cargoType = ZJCargoType.filter(item => item.code === params.value);
    return cargoType[0].value;
  }

  vc = (params) => {
    const carColor = vc.filter(item => item.code === params.value);
    return carColor[0].value;
  }

  messageState = (params) => {
    const state = messageState.filter(item => item.code === params.value);
    return state.length > 0 ? state[0].value : '';
  }

  dateFormat(formatString: string = 'YYYY-MM-DD HH:mm') {
    return (params) => this.util.dateFormat(params.value, formatString);
  }

  renderAddress(type: string) {
    if (type === 'start') {
      return (params) => Array.from(new Set(this.mergeStart(params))).join(' ');
    } else if (type === 'end') {
      return (params) => Array.from(new Set(this.mergeEnd(params))).join(' ');
    }
  }

  mergeStart(params): string[] {
    const model: any = params.data;
    const value1 = model.carriageStartPlaceProvince;
    const value2 = model.carriageStartPlaceCity;
    const value3 = model.carriageStartPlaceCounty;
    if (!model.carriageStartPlaceCity) {
      return [value1];
    } else if (!!model.carriageStartPlaceCity && !!model.carriageStartPlaceCounty) {
      return [value1, value2, value3];
    } else if (!model.carriageStartPlaceCounty) {
      return [value1, value2];
    }
  }

  mergeEnd(params): string[] {
    const model: any = params.data;
    const value1 = model.destinationProvince;
    const value2 = model.destinationCity;
    const value3 = model.destinationCounty;
    if (!model.destinationCity) {
      return [value1];
    } else if (!!model.destinationCity && !!model.destinationCounty) {
      return [value1, value2, value3];
    } else if (!model.destinationCounty) {
      return [value1, value2];
    }
  }

  mergeAddress(model: any, key: string): string[] {
    // for (let key in model) {
    let parts = [];
    if (model[key]) {
      parts = model[key].split('_');
      if (parts[0] === parts[1]) {
        return [parts[0]]
      } else {
        return parts;
      }
    }
    // }
  }

  paymentState = (params) => {
    const state = paymentState.filter(item => item.code === params.value);
    return state.length > 0 ? state[0].value : '';
  }

  freightType = (params) => {
    const state = freightType.filter(item => item.code == params.value);
    return state.length > 0 ? state[0].value : '';
  }

  cargoType = (params) => {
    const state = cargoType.filter(item => item.code == params.value);
    return state.length > 0 ? state[0].value : '';
  }

  syncType = (params) => {
    const state = syncType.filter(item => item.code === params.value);
    return state.length > 0 ? state[0].value : '';
  }

  areaType = (params) => {
    const state = areaType.filter(item => item.code === params.value);
    return state.length > 0 ? state[0].value : '';
  }

  workType = (params) => {
    const state = workType.filter(item => item.code === params.value);
    return state.length > 0 ? state[0].value : '';
  }

  markType = (params) => {
    const state = markType.filter(item => item.code === params.value);
    return state.length > 0 ? state[0].value : '';
  }

  businType = (params) => {
    const state = businCode.filter(item => item.code === params.value);
    return state.length > 0 ? state[0].value : '';
  }

  bindFlag = (params) => {
    const state = bindFlag.filter(item => item.code === params.value);
    return state.length > 0 ? state[0].value : '';
  }

  signCardType = (params) => {
    const state = signCardType.filter(item => item.code === params.value);
    return state.length > 0 ? state[0].value : '';
  }

  signAccType = (params) => {
    const state = signAccType.filter(item => item.code === params.value);
    return state.length > 0 ? state[0].value : '';
  }

  signState = (params) => {
    const state = signState.filter(item => item.code === params.value);
    return state.length > 0 ? state[0].value : '';
  }

  syncState = (params) => {
    const state = syncState.filter(item => item.code === params.value);
    return state.length > 0 ? state[0].value : '';
  }

  isVIP = (params) => {
    const state = isVIP.filter(item => item.code === params.value);
    return state.length > 0 ? state[0].value : '';
  }

  msgData = (params) => {
    const data = JSON.parse(params.value);
    return data.alarmName ? data.alarmName : data;
  }

  rendererLogType(params) {
    const state = logType.filter(item => item.value === params.value);
    return state.length > 0 ? state[0].name : '';
  }
}
