import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {
  isCollapsed = false;
  title = '管理后台';

  constructor(
    @Inject(DOCUMENT) private doc: Document
  ) { }

  ngOnInit() {
    setTimeout(() => {
      this.changeColl();
    }, 0);
  }

  @HostListener('window:resize', ['$event'])
  _resize(e) {
    this.changeColl();
  }

  private changeColl() {
    const browserW =  this.doc.documentElement.clientWidth || this.doc.body.clientWidth;
    this.isCollapsed = browserW < 1200;
  }
}
