import { NgModule } from '@angular/core';
import { MainComponent } from './main/main.component';
import {SharedModule} from '@shared/shared.module';
import {UserSettingComponent} from './user-setting/user-setting.component';
import { BreadComponent } from './bread/bread.component';
import { MenuTreeComponent } from './menu-tree/menu-tree.component';
import { PassportComponent } from './passport/passport.component';
import {FullscreenComponent} from './fullscreen.component';

@NgModule({
  declarations: [
    MainComponent,
    UserSettingComponent,
    BreadComponent,
    MenuTreeComponent,
    PassportComponent,
    FullscreenComponent
  ],
  imports: [
    SharedModule
  ],
  exports: [
    MainComponent,
    PassportComponent
  ]
})
export class LayoutModule { }
