import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NzMessageService, NzModalRef} from 'ng-zorro-antd';
import { RichTextEditorService } from '../service/rich-text-editor.service';
import { UtilService } from '@core/service/util.service';
import { REQUEST } from '@core/config/config';
import { GridColumnService } from '@core/service/grid-column.service';

@Component({
  selector: 'app-insert-val',
  templateUrl: './insert-val.component.html',
  styleUrls: ['./insert-val.component.scss']
})
export class InsertValComponent implements OnInit {

  @Input() model: any;
  insertForm: FormGroup;
  insertFormJson: Array<any>;
  tplModal: NzModalRef;

  constructor(
    private richTextEditorService: RichTextEditorService,
    private util: UtilService,
    private gridColumn: GridColumnService,
    private msg: NzMessageService
  ) {
  }

  ngOnInit(): void {
    this.insertFormJson = this.richTextEditorService.getInsertFormJson();
    this.insertForm = this.util.toFormGroup(this.insertFormJson);
  }


}
