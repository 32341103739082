import {Component, Input, Renderer2} from '@angular/core';
import {REQUEST} from '@core/config/config';
import {NzMessageService} from 'ng-zorro-antd';

@Component({
  selector: 'app-detail-list',
  templateUrl: './detail-list.component.html',
  styleUrls: ['./detail-list.component.scss']
})
export class DetailListComponent {
  @Input() title: string;
  @Input() lists: Array<{key: string, value: string}>;
  @Input() model: any;
  @Input() type: 'string' | 'img';
  @Input() rendererInfo: (e) => void = null; // 支持信息自定义渲染
  baseUrl: string;
  previewVisible = false;
  previewImageUrl: string;
  previewImageTitle: string;
  tooltipTitle = '点击查看原图';
  itemPics = [];
  constructor(
    private msg: NzMessageService,
    private renderer: Renderer2
  ) {
    this.baseUrl = REQUEST.DISPLAY_PIC_BASE;
  }

  ngOnInit(){
    if(this.type === 'img') {
      this.handleUrl();
    }
  }

  /**
   * 根据key获取信息
   * @param key
   */
  getInfoByKey(key: string): string {
    return !!this.rendererInfo ? this.rendererInfo(key) : this.model[key];
  }

  handleUrl(){
    let reg = /;/g;
    this.lists.map(item => {
      if (reg.test(this.model[item.key])) {
        this.itemPics.push({value:item.value, pic:this.model[item.key].split(';')})
      } else {
        this.itemPics.push({value:item.value, pic:[this.model[item.key]]})
      }
    })
  }
  /**
   * 查看原图
   * @param item
   * @param e
   */
  showPic(e, list: {value: string, pic: Array<string>}, item, index) {
    const src = e.target.src;
    if (src.indexOf('error.png') === -1) {
      this.previewImageUrl = this.baseUrl + item;
      this.previewImageTitle = list.value + (list.pic.length > 1 ? `${index}` : '');
      this.previewVisible = true;
    } else {
      this.msg.error('图片未上传或已丢失！');
    }
  }

  showError(e) {
    this.renderer.setAttribute(e.target, 'src', 'assets/img/error.png');
  }

  showTitle(e) {
    const src = e.target.src;
    if (src.indexOf('error.png') === -1) {
      this.tooltipTitle = '点击查看原图';
    } else {
      this.tooltipTitle = '图片未上传或已丢失！';
    }
  }
}
