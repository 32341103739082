import {Component, ElementRef, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {Actions} from '@shared/model/baseModel';
import {Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {UtilService} from '@core/service/util.service';

@Component({
  selector: 'app-base-action',
  templateUrl: './base-action.component.html',
  styleUrls: ['./base-action.component.scss']
})
export class BaseActionComponent implements OnInit, OnDestroy {
  @Input() actions: Actions[] = [];
  actionsLeft: Actions[] = [];
  actionsRight: Actions[] = [];
  element: HTMLElement;
  showDrop = false;
  private showResize = new Subject<any>();
  private destroy$ = new Subject();
  constructor(
    private el: ElementRef,
    public util: UtilService
  ) {
    this.element = this.el.nativeElement as HTMLElement;
  }

  ngOnInit() {
    if (this.actions.length > 0) {
      this.actions.map(item => {
        if (!!item.isEnd) {
          this.actionsRight.push(item);
        } else {
          this.actionsLeft.push(item);
        }
      });
    }
    this.showResize.pipe(
      debounceTime(500),
      takeUntil(this.destroy$)
    ).subscribe(e => this.showDropDown());
    this.showResize.next();
  }

  @HostListener('window:resize')
  _resize() {
    this.showResize.next();
  }

  showDropDown() {
    const baseAction = this.element.querySelector('.base-action');
    this.showDrop = baseAction.clientWidth + 5 < baseAction.scrollWidth　&& this.util.showActionsColumns(this.actions).length > 0;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

}
