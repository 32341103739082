import {FormControl} from '@angular/forms';
import * as IDValidator from 'id-validator';

// 身份证校验
export const idCardValidator = (control: FormControl): { [s: string]: boolean } => {
  if (!control.value) {
    return {required: true};
  } else {
    const Validator = new IDValidator();
    const isIDCard: boolean = Validator.isValid(control.value, 18);
    return isIDCard ? {} : {idCard: true, error: true};
  }
};

// 手机号码检验
export const mobileValidator = (control: FormControl): { [s: string]: boolean } => {
  if (!control.value) {
    return {required: true};
  } else {
    const reg = /^(13[0-9]|14[579]|15[0-3,5-9]|16[6]|17[0135678]|18[0-9]|19[89])\d{8}$/;
    if (!reg.test(control.value)) {
      return {mobile: true, error: true};
    }
  }
  return {};
};

// 车牌号码校验
export const plateNumValidator = (control: FormControl): { [s: string]: boolean } => {
  if (!control.value) {
    return {required: true};
  } else {
    const reg = /^([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z][a-zA-Z](([DF]((?![IO])[a-zA-Z0-9](?![IO]))[0-9]{4})|([0-9]{5}[DF]))|[京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z][A-Z][A-Z0-9]{4}[A-Z0-9挂学警港澳])$/;
    if (!reg.test(control.value)) {
      return {plateNum: true, error: true};
    }
  }
  return {};
};

export const accValidator = (control: FormControl): { [s: string]: boolean } => {
  if (!control.value && control.value !== 0) {
    return {required: true};
  } else {
    const reg = /^(([0]*)|([1-9][0-9]*)|(([0]\.\d{1,4}|[1-9][0-9]*\.\d{1,4})))$/;
    if (!reg.test(control.value)) {
      return {cash: true, error: true};
    }
  }
  return {};
};