import {ModuleWithProviders, NgModule, Optional, SkipSelf} from '@angular/core';
import {DA_STORE_TOKEN, DelonAuthConfig, SessionStorageStore} from '@delon/auth';
import {throwIfAlreadyLoaded} from '@core/module-import-guard';
import {DelonACLConfig, DelonACLModule} from '@delon/acl';

/**
 * auth模块配置
 * @returns {DelonAuthConfig}
 */
export function delonAuthConfig(): DelonAuthConfig {
  return Object.assign(new DelonAuthConfig(), {
    login_url: '/passport/login',
    ignores: [ /\/login/, /assets\//, /passport\//, /\/token/, /\/publicInfo\//]
  } as DelonAuthConfig);
}

/**
 * acl模块配置
 * @returns {DelonACLConfig}
 */
export function delonACLConfig(): DelonACLConfig {
  return Object.assign(new DelonACLConfig(), {
    guard_url: 'exception/403'
  } as DelonACLConfig);
}

@NgModule({
  imports: [DelonACLModule.forRoot()]
})
export class DelonModule {
  constructor(@Optional() @SkipSelf() parentModule: DelonModule) {
    throwIfAlreadyLoaded(parentModule, 'DelonModule');
  }
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: DelonModule,
      providers: [
        { provide: DA_STORE_TOKEN, useClass: SessionStorageStore },
        { provide: DelonAuthConfig, useFactory: delonAuthConfig},
        { provide: DelonACLConfig, useFactory: delonACLConfig}
      ]
    };
  }
}
