import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';

import {NgZorroAntdModule} from 'ng-zorro-antd';
import {AgGridModule} from 'ag-grid-angular';
import 'ag-grid-enterprise';
import {DelonACLModule} from '@delon/acl';
import {DelonChartModule} from '@delon/chart';
import {AlainThemeModule} from '@delon/theme';
import { EditorModule } from '@tinymce/tinymce-angular';
import { AbmModule } from 'angular-baidu-maps';

import {PipeModule} from './pipe/pipe.module';
import {DebounceClickDirective} from './directive/debounce-click.directive';
import {StringTemplateOutletDirective} from './directive/string-template-outlet.directive';
import {InputNoSpaceDirective} from './directive/no-space.directive';

import {BaseGridComponent} from './component/base-grid/base-grid.component';
import {ExceptionComponent} from './component/exception/exception.component';
import {ActionsRendererComponent} from './component/base-grid/component/actions-renderer.component';
import {BaseActionComponent} from './component/base-action/base-action.component';
import {BaseFormComponent} from './component/base-form/base-form.component';
import {FileUploadComponent} from './component/file-upload/file-upload.component';
import {DetailListComponent} from './component/detail-list/detail-list.component';
import {LoadingPageComponent} from './component/loading-page/loading-page.component';
import {QueryGridComponent} from './component/query-grid/query-grid.component';
import {ImportFileComponent} from './component/import-file/import-file.component';
import {ImgViewerModule} from 'ng-picture-viewer';
import {PdfViewerComponent} from './component/pdf-viewer/pdf-viewer.component';
import {InfoVerificateComponent} from './component/info-verificate/info-verificate.component';
import { BatchOperateListComponent } from './component/batch-operate-list/batch-operate-list.component';
import { OrderDetailComponent } from './component/order-detail/order-detail.component';
import { RichTextEditorComponent } from './component/rich-text-editor/rich-text-editor.component';
import { TempPreviewComponent } from './component/temp-preview/temp-preview.component';
import { InsertValComponent } from './component/rich-text-editor/insert-val/insert-val.component';
import { ConditionExportComponent } from './component/condition-export/condition-export.component';
import { TrackComponent } from './component/track/track.component';

// #region your components & directives
const EXTRACOMPONENTS = [ActionsRendererComponent];

const COMPONENTS = [
  BaseGridComponent,
  ExceptionComponent,
  BaseActionComponent,
  BaseFormComponent,
  FileUploadComponent,
  DetailListComponent,
  LoadingPageComponent,
  QueryGridComponent,
  ImportFileComponent,
  PdfViewerComponent,
  InfoVerificateComponent,
  BatchOperateListComponent,
  OrderDetailComponent,
  RichTextEditorComponent,
  TempPreviewComponent,
  InsertValComponent,
  ConditionExportComponent,
  TrackComponent,
  ...EXTRACOMPONENTS
];
const DIRECTIVES = [DebounceClickDirective, StringTemplateOutletDirective, InputNoSpaceDirective];

const THIRDMODULES = [NgZorroAntdModule, AgGridModule.withComponents(EXTRACOMPONENTS)];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...DIRECTIVES,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DelonACLModule,
    DelonChartModule,
    AlainThemeModule,
    ImgViewerModule,
    EditorModule,
    AbmModule,
    ...THIRDMODULES
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgZorroAntdModule,
    PipeModule,
    DelonACLModule,
    DelonChartModule,
    AlainThemeModule,
    ImgViewerModule,
    AbmModule,
    ...COMPONENTS,
    ...DIRECTIVES
  ],
  entryComponents: [
    QueryGridComponent,
    ImportFileComponent,
    PdfViewerComponent,
    OrderDetailComponent,
    TempPreviewComponent,
    InsertValComponent,
    ConditionExportComponent
  ]
})

export class SharedModule {
}
