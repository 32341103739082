import { Component, Inject, OnInit } from '@angular/core';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilService } from '@core/service/util.service';
import { SettingService } from '@core/service/setting.service';
import * as Driver from 'driver.js';
import { Step } from 'driver.js';
import { MessageService } from '@core/service/message.service';
import { ACLService } from '@delon/acl';
import { HttpService } from '@core/service/http.service';
import { REQUEST } from '@core/config/config';
import { NzMessageService } from 'ng-zorro-antd';

@Component({
  selector: 'app-user-setting',
  templateUrl: './user-setting.component.html',
  styleUrls: ['./user-setting.component.scss']
})
export class UserSettingComponent implements OnInit {
  userName: string;
  driver: any;
  steps: Step[] = [
    {
      element: '.treeMenu',
      popover: {
        title: '菜单',
        description: '切换不同的功能页面',
        position: 'right'
      }
    },
    {
      element: '.trigger',
      popover: {
        title: '菜单切换',
        description: '打开或关闭左侧菜单',
        position: 'bottom'
      }
    },
    {
      element: '.full-screen',
      popover: {
        title: '全屏切换器',
        description: '设置页面全屏',
        position: 'bottom'
      }
    },
    {
      element: '.user',
      popover: {
        title: '用户信息',
        description: '鼠标悬浮可进行个人设置或退出系统操作',
        position: 'left'
      }
    },
    {
      element: '.ant-breadcrumb',
      popover: {
        title: '面包屑导航',
        description: '指示当前页面位置',
        position: 'bottom'
      }
    }
  ];
  count = 0;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private util: UtilService,
    public setting: SettingService,
    private message: MessageService,
    private http: HttpService,
    private msg: NzMessageService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private aclService: ACLService,
  ) {
    this.message.count.subscribe((data: number) => this.count = data);
  }

  ngOnInit() {
    this.driver = new Driver({
      doneBtnText: '结束',
      closeBtnText: '关闭',
      nextBtnText: '下一步',
      prevBtnText: '上一步'
    });
  }

  securitySetting() {
    // this.router.navigate(['/securitySetting/sysSetting'], {relativeTo: this.route});
  }

  logout() {
    this.util.submitConfirm('退出系统').then(() => {
      this.http.post(`${REQUEST.LOGOUT}`, '').subscribe((res) => {
        if (res) {
        }
      });
      this.tokenService.clear();
      this.setting.clearSetting();
      this.message.close();
      this.aclService.setRole(['GUEST']);
      this.router.navigateByUrl(this.tokenService.login_url);
    });
  }

  guide(e) {
    // 必须阻止捕获和冒泡
    e.stopPropagation();
    this.driver.defineSteps(this.steps);
    this.driver.start();
  }

}
