import { Injectable } from '@angular/core';
import { REQUEST } from '@core/config/config';
import { HttpService } from '@core/service/http.service';
import { BaseForm, TextboxForm } from '@shared/model/baseForm';
import { Temp } from '@shared/model/baseModel';

@Injectable({
  providedIn: 'root'
})
export class RichTextEditorService {

  constructor(
    private http: HttpService
    ) { }

  // 上传图片
  addImg(body: any) {
    return this.http.post('/v1/files', body);
  }

  getInsertFormJson = () => {
    const addInsert: BaseForm<any>[] = [
      new TextboxForm({
        key: 'key',
        label: 'key',
        value: '',
        placeholder: '请输入英文字段',
        required: true,
        order: 1
      }),
      new TextboxForm({
        key: 'label',
        label: 'label',
        value: '',
        placeholder: '请输入中文字段名',
        required: true,
        order: 2
      }),
    ]
    return addInsert.sort((a, b) => a.order - b.order);
  }
}
