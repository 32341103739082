import {Component, Input, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {BaseForm} from '@shared/model/baseForm';
import * as _ from 'lodash';

@Component({
  selector: 'app-base-form',
  templateUrl: './base-form.component.html',
  styleUrls: ['./base-form.component.scss']
})
export class BaseFormComponent implements OnInit {
  @Input() baseForm: FormGroup;
  @Input() formJsons: BaseForm<any>[];
  constructor() { }

  ngOnInit() {
  }

  compareFn = (o1: any, o2: any) => {
    return (_.isObjectLike(o1) && o1 && o2) ? o1.code === o2.code : o1 === o2;
  }

    /**
   * Label显示判断
   * @param form
   * @returns {boolean}
   */
  showLabel(form: BaseForm<any>): boolean {
    return !!form.label && form.controlType !== 'uploadbox' && form.controlType !== 'richText';
  }

  /**
   * 表单宽度判读
   * @param form
   * @returns {number}
   */
  formWidth(form: BaseForm<any>): number {
    switch (form.controlType) {
      case 'richText':
      case 'uploadbox':
        return 24;
      default:
        return 15;
    }
  }

  /**
   * item宽度判断
   * @param form
   * @returns {string}
   */
  itemWidth(form: BaseForm<any>): string {
    switch (form.controlType) {
      case 'richText':
        return '100%';
      case 'uploadbox':
        return '180px';
      default:
        return '';
    }
  }
}
