import { Component, HostListener, ChangeDetectionStrategy } from '@angular/core';
import * as screenfull from 'screenfull';

@Component({
  selector: 'app-fullscreen',
  template: `
    <i nz-tooltip [nzTooltipTitle]="status ? '退出全屏' : '全屏'" nz-icon [nzType]="status ? 'fullscreen-exit' : 'fullscreen'"></i>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FullscreenComponent {
  status = false;
  private get sf(): screenfull.Screenfull {
    return screenfull as unknown as screenfull.Screenfull;
  }

  @HostListener('window:resize')
  _resize() {
    this.status = this.sf.isFullscreen;
  }

  @HostListener('click')
  _click() {
    if (this.sf.enabled) {
      this.sf.toggle();
    }
  }
}
