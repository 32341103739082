import { Pipe, PipeTransform } from '@angular/core';

// Regular Expressions for parsing tags and attributes
const SURROGATE_PAIR_REGEXP = /[\uD800-\uDBFF][\uDC00-\uDFFF]/g;
// ! to ~ is the ASCII range.
const NON_ALPHANUMERIC_REGEXP = /([^\#-~ |!])/g;

/**
 * Escapes all potentially dangerous characters, so that the
 * resulting string can be safely inserted into attribute or
 * element text.
 */
function encodeEntities(value: string): string {
  return value
    .replace(/&/g, '&amp;')
    .replace(SURROGATE_PAIR_REGEXP, (match: string) => {
      const hi = match.charCodeAt(0);
      const low = match.charCodeAt(1);
      return `&#${(hi - 0xD800) * 0x400 + (low - 0xDC00) + 0x10000};`;
    })
    .replace(NON_ALPHANUMERIC_REGEXP, (match: string) => `&#${match.charCodeAt(0)};`)
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;');
}

@Pipe({
  name: 'nzHighlight',
  pure: true
})
export class NzHighlightPipe implements PipeTransform {
  transform(value: string, highlightValue: string, flags?: string, klass?: string): string | null {
    // Make it to display HTML string
    const encodedValue = encodeEntities(value);

    if (!highlightValue) {
      return encodedValue;
    }

    const encodedHighlightValue = encodeEntities(highlightValue);

    // Escapes regex keyword to interpret these characters literally
    const searchValue = new RegExp(encodedHighlightValue.replace(/([.*+?^=!:${}()|[\]\/\\])/g, '\\$&'), flags);
    return encodedValue.replace(searchValue, str => `<span${klass ? ` class="${klass}"` : ''}>${str}</span>`);
  }
}
