import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {IdCardInfoPipe} from './idCardInfo/id-card-info.pipe';
import { DateFormatPipe } from './dateFormat/date-format.pipe';
import { NzHighlightPipe } from '@shared/pipe/highlight/highlight.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [
    IdCardInfoPipe,
    DateFormatPipe,
    NzHighlightPipe,
  ],
  exports: [
    IdCardInfoPipe,
    DateFormatPipe,
    NzHighlightPipe
  ]
})
export class PipeModule { }
