import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Menu } from '@shared/model/baseModel';
import { Menus } from '@core/config/config';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ACLService } from '@delon/acl';

@Component({
  selector: 'app-menu-tree',
  templateUrl: './menu-tree.component.html',
  styleUrls: ['./menu-tree.component.scss']
})
export class MenuTreeComponent implements OnInit, OnDestroy {
  @Input() isCollapsed: boolean;
  menus: Menu[] = Menus;
  url: string;
  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private aclService: ACLService,
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.url = this.router.url.substr(1, this.router.url.length);
      this.setExpand(this.menus);
    });
  }

  ngOnInit() {
    this.menus = this.filterMenus(this.menus);
  }

  setExpand(sources: Menu[]) {
    for (const source of sources) {
      if (this.url.indexOf(source.id) > -1) {
        source.expand = true;
        source.selected = true;
        if (source.hasSub) {
          this.setExpand(source.subMenus);
        }
      } else {
        source.expand = false;
        source.selected = false;
      }
    }
  }

  openChange(menu: Menu, subList: Menu[]) {
    menu.selected = !menu.selected;
    menu.expand = !menu.expand;
    this.closeMenu(menu, subList)
  }

  closeMenu(menu: Menu, subList: Menu[]) {
    subList.forEach(item => {
      item.expand = menu.id === item.id;
      item.selected = menu.id === item.id;
      if (item.subMenus) {
        this.closeMenu(menu, item.subMenus)
      }
    });
  }

  filterMenus(menus: Menu[]): Menu[] {
    menus.map((item: Menu) => {
      if (item.guard) {
        item.isShow = this.aclService.canAbility(item.guard);
      } else {
        item.isShow = true;
      }
      if (item.hasSub) {
        this.filterMenus(item.subMenus);
      }
    });
    return menus;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
