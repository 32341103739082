import { Inject, Injectable, Injector } from '@angular/core';
import { NzNotificationService, NzMessageService, NzModalService } from 'ng-zorro-antd';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { BackRes, Message } from '@shared/model/baseModel';
import { delay, retryWhen, takeUntil } from 'rxjs/operators';
import { REQUEST } from '@core/config/config';
import { HttpService } from '@core/service/http.service';
import { NotifyPushService } from '@core/service/push.service';
import { Router } from '@angular/router';
import { UtilService } from './util.service';

@Injectable({
  providedIn: 'root'
})
export class MessageService {
  private destroy$ = new Subject();
  private ws: WebSocketSubject<any>;
  private count$ = new BehaviorSubject<number>(null);
  syncing$ = new BehaviorSubject<boolean>(true);
  private open$ = new Subject();
  hassPushMsg = false;

  constructor(
    private notification: NzNotificationService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private http: HttpService,
    // tslint:disable-next-line:variable-name
    private _nps: NotifyPushService,
    private injector: Injector,
    private msg: NzMessageService,
    private modalService: NzModalService,
    private util: UtilService
  ) {
  }

  private goTo(url: string) {
    setTimeout(() => this.injector.get(Router).navigateByUrl(url));
  }

  receive(): void {
    this.ws = webSocket({
      url: `${REQUEST.WSS}/topic/Bearer ${this.tokenService.get().token}`,
      openObserver: this.open$
    });
    this.ws.pipe(
      retryWhen(errors => errors.pipe(delay(1000))),
      takeUntil(this.destroy$)
    ).subscribe((data: Message) => {
      console.log(data)
      if (!!data) {
        if (data.messageType.code === '501' || data.messageType.code === '502') {
          // if(!this.hassPushMsg){
            this.notification.warning(data.messageType.title, data.messageType.desc);
            this.modalService.confirm({
              nzTitle: data.messageType.title,
              nzContent: data.messageType.desc,
              nzOkText: '去登录',
              nzOkType: 'danger',
              nzOnOk: () => {
                (this.injector.get(DA_SERVICE_TOKEN) as ITokenService).clear();
                this.close();
                this.goTo('/passport/login');
                return;
              },
              nzOnCancel: () => {
                (this.injector.get(DA_SERVICE_TOKEN) as ITokenService).clear();
                this.close();
                this.goTo('/passport/login');
                return;
              }
            });
            // this.hassPushMsg = true;
            // }
        } else if(data.messageType.code === '503' || data.messageType.code === '504'){
          this.syncing$.next(false);
          if(!data.detail){
            this.notification.warning(data.messageType.title, data.messageType.desc);
          }else{
            const detail = this.util.handleData(data.detail);
            this.notification.warning(data.messageType.title, detail);
          }
          
        } else {
          this.notification.warning(data.messageType.title, data.messageType.desc);
          this.getCount();
        }
        // 如果浏览器支持桌面通知并且用户允许通知
        if (this._nps.isSupported() && this._nps.permission === 'granted') {
          this._nps.create(data.messageType.title, {
            body: data.messageType.desc,
            icon: 'assets/img/logo.png',
            data
          }).subscribe();
        }
      }
    });
    this.open$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(data => {
      this.getCount();
    });
  }

  close(): void {
    this.destroy$.next();
    this.destroy$.complete();
    if (this.ws) {
      this.ws.unsubscribe();
    }
  }

  get count(): Observable<number> {
    return this.count$.asObservable();
  }

  get syncing(): Observable<boolean> {
    return this.syncing$.asObservable();
  }

  getCount(): void {
    this.http.get(`${REQUEST.MESSAGE.COUNT}`).subscribe((res: BackRes) => {
      if (!res.error) {
        this.count$.next(res.data);
      }
    });
  }
}
