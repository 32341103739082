import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-info-verificate',
  templateUrl: './info-verificate.component.html',
  styleUrls: ['./info-verificate.component.scss']
})
export class InfoVerificateComponent implements OnInit {
  
  @Input() title: string;
  @Input() verifyInfoList: Array<{key: string, value: string}>;
  constructor() { }

  ngOnInit() {
  }

}
