import { Component, forwardRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { toolbar, toolbar1 } from './/config/toolbar';
import { plugins, plugins1 } from './config/plugins';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { REQUEST } from '@core/config/config';
import { EditorComponent } from '@tinymce/tinymce-angular';
import { HttpService } from '@core/service/http.service';
import { BackMsg } from '@shared/model/baseModel';
import { RichTextEditorService } from './service/rich-text-editor.service';
import { NzModalRef, NzModalService } from 'ng-zorro-antd';
import { InsertValComponent } from './insert-val/insert-val.component';

export const DEFAULT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => RichTextEditorComponent),
  multi: true
};

@Component({
  selector: 'app-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: [ './rich-text-editor.component.scss' ],
  providers: [ DEFAULT_VALUE_ACCESSOR ]
})
export class RichTextEditorComponent implements OnInit, ControlValueAccessor, OnDestroy {
  initConfig: any;
  @Input() toolbar: Array<string> = [];
  @Input() menubar = true;
  @Input() width: string | number = 'auto';
  @Input() height: string | number = '100%';
  value: string = null;
  @ViewChild('tinymce', { static: true })
  private tinymce: EditorComponent;
  propagateChange = (_: any) => {};
  body = []

  constructor(
    private http: HttpService,
    private modalService: NzModalService,
    private richTextEditorService: RichTextEditorService
  ) { }

  ngOnInit() {
    this.initConfig = {
      base_url: '/tinymce',
      suffix: '.min',
      plugins: plugins1,
      width: this.width,
      height: this.height,
      resize: false,
      // min_height: this.height,
      // max_height: this.height,
      menubar: this.menubar,
      toolbar: this.toolbar.length > 0 ? this.toolbar : toolbar1,
      quickbars_insert_toolbar: false,
      language: 'zh_CN',
      end_container_on_empty_block: true,
      powerpaste_word_import: 'clean',
      advlist_bullet_styles: 'square',
      advlist_number_styles: 'default',
      default_link_target: '_blank',
      link_title: false,
      nonbreaking_force_tab: true,
      custom_undo_redo_levels: 10,
      imagetools_cors_hosts: ['cdn.test.0935e.com'],
      images_reuse_filename: true,
      images_upload_base_path: REQUEST.DISPLAY_PIC_BASE,
      images_upload_url: REQUEST.UPLOAD_URL,
      images_upload_handler: (blobInfo, success, failure) => {
        const formData = new FormData();
        formData.append('file', blobInfo.blob(), blobInfo.filename());
        this.richTextEditorService.addImg(formData).subscribe(res => {
          if(!res.error){
            success(REQUEST.DISPLAY_PIC_BASE + res.path);
          }else{
            failure(res.message);
          }
        })
      },
      setup: (editor: any) => {
        editor.ui.registry.addButton('ccc', {
          text: 'ccc',
          onAction: () => alert('ccc')
        });
      }
    };
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  insertReplaceVal(){
    const modal: NzModalRef = this.modalService.create({
      nzTitle: '插入模板变量' ,
      nzContent: InsertValComponent,
      nzMaskClosable: false,
      nzWidth: 600,
      nzClosable: true,
      nzFooter: [
        {
          label: '取消',
          onClick: () => modal.destroy()
        },
        {
          label: '确定',
          type: 'primary',
          disabled: com => !com.insertForm.valid,
          onClick: com => {
            const insertForm = JSON.parse(JSON.stringify(com.insertForm.getRawValue()));
            this.body.push(insertForm);
            console.log(this.body)
            this.tinymce.editor.insertContent(`<button style="disabled:true">[[${insertForm.key}]]</button>`);
            modal.close();
          }
        }
      ]
    });
  }

  addBtn(){
    this.tinymce.editor.insertContent(`<button class="" onclick="${this.insertReplaceVal()}">插值按钮</button>`);
  }

  

  writeValue(value: any): void {
    if (!!value) {
      this.value = value;
    }
  }

  valueChange(value: any) {
    this.value = value;
    this.propagateChange(this.value);
  }

  ngOnDestroy(): void {
    if (this.tinymce) {
      this.tinymce.editor.remove();
    }
  }

}
