import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { MainComponent } from '../layout/main/main.component';
import { environment } from '../../environments/environment';
import { PassportComponent } from '../layout/passport/passport.component';
import { LoginComponent } from './passport/login/login.component';
import { JWTGuard } from '@delon/auth';
import { ACLGuard } from '@delon/acl';

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [JWTGuard],
    canActivateChild: [JWTGuard],
    children: [
      { path: '', redirectTo: 'home', pathMatch: 'full' },
      {
        path: 'home', component: HomeComponent,
        data: {
          breadcrumb: '首页数据统计'
        }
      },
      {
        path: 'carManagement', loadChildren: () => import('./car-management/car-management.module').then(m => m.CarManagementModule),
        canLoad: [ACLGuard],
        canActivate: [ACLGuard],
        data: {
          breadcrumb: '车辆管理',
          guard: 100200001
        }
      },
      {
        path: 'userManagement', loadChildren: () => import('./user-management/user-management.module').then(m => m.UserManagementModule),
        canLoad: [ACLGuard],
        canActivate: [ACLGuard],
        data: {
          breadcrumb: '用户管理',
          guard: 100101001
        }
      },
      {
        path: 'invoiceManagement', loadChildren: () => import('./invoice-management/invoice-management.module').then(m => m.InvoiceManagementModule),
        canLoad: [ACLGuard],
        canActivate: [ACLGuard],
        data: {
          breadcrumb: '发票管理',
          guard: 100300001
        }
      },
      {
        path: 'oilcardManagement', loadChildren: () => import('./oilcard-management/oilcard-management.module').then(m => m.OilcardManagementModule),
        canLoad: [ACLGuard],
        canActivate: [ACLGuard],
        data: {
          breadcrumb: '油卡管理',
          guard: 100400001
        }
      },
      {
        path: 'roleManagement', loadChildren: () => import('./role-management/role-management.module').then(m => m.RoleManagementModule),
        canLoad: [ACLGuard],
        canActivate: [ACLGuard],
        data: {
          breadcrumb: '角色管理',
          guard: 100102001
        }
      },
      {
        path: 'logManagement', loadChildren: () => import('./log-management/log-management.module').then(m => m.LogManagementModule),
        data: {
          breadcrumb: '日志管理'
        }
      },
      {
        path: 'driverManagement', loadChildren: () => import('./driver-management/driver-management.module').then(m => m.DriverManagementModule),
        data: {
          breadcrumb: '司机管理',
        }
      },
      {
        path: 'serviceFeeManagement', loadChildren: () => import('./service-fee-management/service-fee-management.module').then(m => m.ServiceFeeManagementModule),
        data: {
          breadcrumb: '服务费管理'
        }
      },
      {
        path: 'orderManagement', loadChildren: () => import('./order-management/order-management.module').then(m => m.OrderManagementModule),
        canLoad: [ACLGuard],
        canActivate: [ACLGuard],
        data: {
          breadcrumb: '订单管理',
          guard: 100800001
        }
      },
      {
        path: 'messageManagement', loadChildren: () => import('./message-management/message-management.module').then(m => m.MessageManagementModule),
        canLoad: [ACLGuard],
        canActivate: [ACLGuard],
        data: {
          breadcrumb: '消息管理',
          guard: 100900001
        }
      },
      {
        path: 'taxManagement', loadChildren: () => import('./tax-management/tax-management.module').then(m => m.TaxManagementModule),
        data: {
          breadcrumb: '税务申报管理'
        }
      },
      {
        path: 'carFreeManagement', loadChildren: () => import('./car-free-management/car-free-management.module').then(m => m.CarFreeManagementModule),
        data: {
          breadcrumb: '无车承运检测管理'
        }
      },
      {
        path: 'parkInformationManagement', loadChildren: () => import('./park-information-management/park-information-management.module').then(m => m.ParkInformationManagementModule),
        data: {
          breadcrumb: '园区信息管理'
        }
      },
      {
        path: 'vipManagement', loadChildren: () => import('./vip-management/vip-management.module').then(m => m.VipManagementModule),
        data: {
          breadcrumb: '账付易管理'
        }
      },
      {
        path: 'conSignManagement', loadChildren: () => import('./con-sign-management/con-sign-management.module').then(m => m.ConSignManagementModule),
        data: {
          breadcrumb: '合同签章管理'
        }
      },
      {
        path: 'partnerManagement', loadChildren: () => import('./partner-management/partner-management.module').then(m => m.PartnerManagementModule),
        canLoad: [ACLGuard],
        canActivate: [ACLGuard],
        data: {
          breadcrumb: '合作伙伴管理',
          guard: 101400001
        }
      },
      {
        path: 'unionManagement', loadChildren: () => import('./union-management/union-management.module').then(m => m.UnionManagementModule),
        canLoad: [ACLGuard],
        canActivate: [ACLGuard],
        data: {
          breadcrumb: '工会认证管理',
          guard: 101500001
        }
      },
      {
        path: 'jimiManagement', loadChildren: () => import('./jimi-management/jimi-management.module').then(m => m.JimiManagementModule),
        data: {
          breadcrumb: '设备管理',
        }
      },
      { path: 'exception', loadChildren: () => import('./exception/exception.module').then(m => m.ExceptionModule) }
    ]
  },
  {
    path: 'passport',
    component: PassportComponent,
    canActivate: [ACLGuard],
    canActivateChild: [ACLGuard],
    data: {
      guard: {
        role: ['GUEST']
      },
      guard_url: '/'
    },
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent }
    ]
  },
  {
    path: 'publicInfo',
    loadChildren: () => import('./public-info/public-info.module').then(m => m.PublicInfoModule),
    component: PassportComponent,
    canActivate: [ACLGuard],
    canActivateChild: [ACLGuard],
    data: {
      guard: {
        role: ['GUEST']
      },
      guard_url: '/'
    },
  },
  { path: '**', redirectTo: 'exception/404' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: environment.useHash })
  ],
  exports: [RouterModule]
})

export class RoutesRoutingModule { }


