import {AsyncValidatorFn, ValidatorFn} from '@angular/forms';
import {TemplateRef} from '@angular/core';
import {UploadFile} from 'ng-zorro-antd';

export class BaseForm<T> {
  value: T;
  key: string;
  label: string;
  required: boolean;
  order: number;
  controlType: string;
  placeholder: string;
  disabled: boolean;
  validators: Array<ValidatorFn | null>;
  asyncValidators: Array<AsyncValidatorFn | null>;
  hidden: boolean;

  constructor(options: {
    value?: T,
    key?: string,
    label?: string,
    required?: boolean,
    order?: number,
    controlType?: string,
    placeholder?: string,
    disabled?: boolean,
    validators?: Array<ValidatorFn | null>,
    asyncValidators?: Array<AsyncValidatorFn | null>,
    hidden?: boolean
  } = {}) {
    this.value = options.value;
    this.key = options.key || '';
    this.label = options.label || '';
    this.required = !!options.required;
    this.order = options.order === undefined ? 1 : options.order;
    this.controlType = options.controlType || '';
    this.placeholder = options.placeholder || '';
    this.disabled = !!options.disabled;
    this.validators = options.validators || [];
    this.asyncValidators = options.asyncValidators || [];
    this.hidden = options.hidden || false;
  }
}

export class DropdownForm extends BaseForm<string | object> {
  controlType = 'dropdown';
  mode: 'multiple' | 'tags' | 'default';
  async: boolean | string;
  options: {key: string, value: string, isDisabled:boolean}[] = [];
  compareFn: any;

  constructor(options: any = {}) {
    super(options);
    this.options = options.options || [];
    this.mode = options.mode || 'default';
    this.async = options.async || false;
    this.compareFn = options.compareFn || null;
  }
}

export class TextboxForm extends BaseForm<string> {
  controlType = 'textbox';
  type: string;
  readOnly: boolean;
  click: (e) => void = (e) => {};

  constructor(options: any = {}) {
    super(options);
    const notwork = (e) => {};
    this.type = options.type || '';
    this.readOnly = options.readOnly || false;
    this.click = options.click || notwork;
  }
}

export class SingleRadioForm extends BaseForm<boolean | number> {
  controlType = 'singleRadio';
  options: {key: string, value: string}[] = [];

  constructor(options: any = {}) {
    super(options);
    this.options = options.options || [];
  }
}

export class NumberForm extends BaseForm<number> {
  controlType = 'numbox';
  min: number;
  max: number;
  precision: number;
  constructor(options: any = {}) {
    super(options);
    this.min = options.min;
    this.max = options.max;
    this.precision = options.precision || 0;
  }
}

export class UploadForm extends BaseForm<string> {
  controlType = 'uploadbox';
  isNecessary = true;
  constructor(options: any = {}) {
    super(options);
    this.isNecessary = options.isNecessary || true;
  }
}

export class InputGroup extends BaseForm<string> {
  controlType = 'input-group';
  before: TemplateRef<void> | string | null;
  after: TemplateRef<void> | string | null;
  constructor(options: any = {}) {
    super(options);
    this.before = options.before || null;
    this.after = options.after || null;
  }
}

export class ImportFile extends BaseForm<UploadFile> {
  controlType = 'import-file';
}

export class AreaForm extends BaseForm<string> {
  controlType = 'areabox';
  maxlen: number;
  constructor(options: any = {}) {
    super(options);
    this.maxlen = options.maxlen;
  }
}

export class MonthForm extends BaseForm<string> {
  controlType = 'month-pickerbox';
  width : string;
  constructor(options: any = {}) {
    super(options);
    this.width = options.width + 'px';
  }
}

export class DateForm extends BaseForm<string> {
  controlType = 'date-pickerbox';
  width : string;
  isShowTime: boolean = false;
  constructor(options: any = {}) {
    super(options);
    this.width = options.width + 'px';
    this.isShowTime = options.isShowTime;
  }
}

export class RangeDateForm extends BaseForm<string> {
  controlType = 'range-pickerbox';
  width : string;
  disabledDate: (startValue: Date)=>boolean;
  constructor(options: any = {}) {
    super(options);
    this.width = options.width + 'px';
    this.disabledDate = options.disabledDate;
  }
}

export class CascaderForm extends BaseForm<string> {
  controlType = 'cascaderbox';
  options =  [];
  constructor(options: any = {}) {
    super(options);
    this.options = options.options || [];
  }
}

export class RichText extends BaseForm<string> {
  controlType = 'richText';
  height: string | number = 500;
  constructor(options: any = {}) {
    super(options);
    this.height = options.height || 500;
  }
}
