import {Inject, Injectable, Injector} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {catchError} from 'rxjs/internal/operators';
import {zip} from 'rxjs/internal/observable/zip';
import {BASE, REQUEST} from '@core/config/config';
import {SettingService} from '@core/service/setting.service';
import {Role, User} from '@shared/model/baseModel';
import {ACLService} from '@delon/acl';
import {DA_SERVICE_TOKEN, ITokenService} from '@delon/auth';
import {MessageService} from '@core/service/message.service';

export function StartupServiceFactory(startupService: StartupService) {
  return () => startupService.load();
}
/**
 * 用于应用启动时
 * 一般用来获取应用所需要的基础数据等
 */
@Injectable()
export class StartupService {
  private headers = new HttpHeaders();

  // 防止造成循环依赖
  constructor(
    private http: HttpClient,
    private setting: SettingService,
    private aclService: ACLService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private injector: Injector
  ) {
    this.headers = this.headers.set('Accept', 'application/x-spring-data-verbose+json');
  }

  /**
   * 根据角色里面是否有管理员角色判断用户是否为管理员
   * @param roles
   * @returns {boolean}
   */
  static isAdmin(roles: Role[]): boolean {
    const adminRole = roles.filter((role: Role) => role.roleType === 1);
    return adminRole.length >= 1;
  }

  /**
   * 过滤出管理后台用户关联的所有权限点
   * @param operations
   * @returns {any[]}
   */
  static getAbility(operations: any[]): number[] {
    const manageOperations = operations.filter(item => item.appId === 10);
    return manageOperations.length > 0 ? manageOperations[0].operIds : [];
  }

  load(): Promise<any> {
    // only works with promises
    // https://github.com/angular/angular/issues/15088
    return new Promise(resolve => {
      this.aclService.setRole(['GUEST']);
      const user: User = this.setting.getUser;
      if (!!user.userId && !!this.tokenService.get().token) {
        zip(
          this.http.get(`${BASE}${REQUEST.USER.ALL}/${user.userId}/roles`, {headers: this.headers}),
          this.http.get(`${BASE}${REQUEST.USER.ALL}/${user.userId}/operations`, {headers: this.headers}),
        ).pipe(// 接收其他拦截器后产生的异常消息
          catchError(([roles, operations]) => {
            resolve(null);
            return [roles, operations];
          })
        ).subscribe(([roles, operations]) => {
            // this.setting.setRoles(roles.content);
            // this.setting.setOperations(operations.content);
            const isAdmin: boolean = StartupService.isAdmin(roles.content);
            const ability: number[] = StartupService.getAbility(operations.content);
            this.setting.setUser(Object.assign(user, {isAdmin}));
            // 如果为admin不受限，否则设置权限点。
            if (isAdmin) {
              this.aclService.setFull(true);
            } else {
              this.aclService.setFull(false);
              this.aclService.setAbility(ability);
            }
            this.aclService.removeRole(['GUEST']);
            this.injector.get(MessageService).receive();
          },
          () => {},
          () => {
            resolve(null);
          });
      } else {
       resolve(null);
      }
    });
  }
}
