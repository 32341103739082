import { AfterViewInit, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ContentChild } from '@angular/core';
import { LOCALTEXT } from './config/localText';
import { GridApi, GridOptions, ColumnApi } from 'ag-grid-community';
import { HttpService } from '@core/service/http.service';
import { PageParams } from './config/page-params';
import * as _ from 'lodash';

@Component({
  selector: 'app-base-grid',
  templateUrl: './base-grid.component.html',
  styleUrls: ['./base-grid.component.scss']
})
export class BaseGridComponent implements OnInit, AfterViewInit {
  @Input() requestUrl: string = null;
  @Input() columnDefs: Array<object> = null;
  @Input() method: 'get' | 'post' = 'get';
  @Input() gridOptions: object = null;
  @Input() selfData = false;
  @Input() rowData: Array<object> = null;
  @Input() queryParams: object;
  @Input() gridClass = 'base-grid';
  @Input() gridStyle = {};
  @Input() listType: string; // 防止返回数据list名称不一致
  @Input() sizeOptions: number[] = [10, 25, 50, 100];
  @Input() toolBar: TemplateRef<void> | string = '';
  @Input() initialLoad = true;
  @Input() isBank = false;
  @ContentChild('infoTips', { static: true }) infoTips: TemplateRef<void>;
  @Output() rowClicked: EventEmitter<any> = new EventEmitter();
  @Output() rowDbClicked: EventEmitter<any> = new EventEmitter();
  @Output() cellChanged: EventEmitter<any> = new EventEmitter();
  @Output() checkChanged: EventEmitter<any> = new EventEmitter();

  gridApi: GridApi;
  gridColumnApi: ColumnApi;
  pageParams: PageParams;
  myContext: '我传出来啦！'

  constructor(
    private httpService: HttpService
  ) {
    this.pageParams = this.getDefaultPageParams;
  }

  ngOnInit() {
    this.gridOptions = Object.assign(this.getDefaultGridOption, this.gridOptions);
  }

  ngAfterViewInit(): void {
    if (this.initialLoad) {
      this.load();
    } else {
      this.rowData = [];
    }
  }

  onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // this.gridApi.sizeColumnsToFit();
  }

  pageIndexChange(index) {
    this.pageParams.number = index;
    this.load();
  }

  pageSizeChange(size) {
    this.pageParams.size = size;
    this.load();
  }

  rowClick(params) {
    this.rowClicked.emit(params.data);
  }

  rowDbClick(params) {
    this.rowDbClicked.emit(params.data);
  }

  cellValueChanged(params) {
    // 解决单元格编辑每次都会触发 https://github.com/ag-grid/ag-grid/issues/2592
    const o1 = params.oldValue;
    const o2 = params.newValue;
    const flag = (_.isObjectLike(o1) && o1 && o2) ? o1.code !== o2.code : o1 !== o2;
    if ((!o1 && !!o2) || flag) {
      this.cellChanged.emit(params.data);
    }
  }

  refresh() {
    this.pageParams.number = 1;
    this.load();
  }

  checkChange(params) {
    this.checkChanged.emit(params.data);
  }

  getFieldList() {
    return this.gridApi.getSelectedRows();
  }

  private load() {
    if (this.selfData) {
      return;
    }
    this.httpService.get(this.requestUrl, Object.assign({
      page: this.pageParams.number,
      per_page: this.pageParams.size
    }, this.queryParams)).subscribe((res) => {
      if (!res.error) {
        if (this.isBank) {
          this.rowData = res.data.bankCards;
          this.pageParams = this.getDefaultPageParams;
        } else {
          this.pageParams = res.page;
          this.rowData = res.content;
        }
        // this.rowData = res._embedded && res._embedded[this.listType];
      } else {
        this.pageParams = this.getDefaultPageParams;
        this.rowData = [];
      }
    });
  }

  private get getDefaultGridOption(): GridOptions {
    return {
      defaultColDef: {
        sortable: true,
        filter: true,
        resizable: true,
        cellStyle: {
          fontSize: '14px',
          lineHeight: '38px'
        }
      },
      rowHeight: 40,
      suppressRowClickSelection: false,
      suppressRowHoverHighlight: false,
      rowSelection: 'multiple',
      localeText: LOCALTEXT,
      context: {
        grid: this
      }
    };
  }

  private get getDefaultPageParams(): PageParams {
    return {
      number: 1,
      size: this.sizeOptions[0],
      totalElements: 0,
      totalPages: 0
    };
  }

}
