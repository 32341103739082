import { Injectable } from '@angular/core';
import {HttpService} from '@core/service/http.service';
import {REQUEST} from '@core/config/config';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  constructor(
    private http: HttpService
  ) { }
/*----------------------------目前接口如果出现客户端和服务端日期不一致的情况，统计出错---------------------------*/
  /**
   * Garbage interface(按天统计)
   * @param day
   * @returns {Observable<any>}
   */
  getStatistics(type: number): Observable<any> {
    return this.http.get(`${REQUEST.STATISTICS.ALL}`, {
      type
    });
  }

  /**
   * Garbage interface(按时间段统计)
   * @param start
   * @param end
   * @returns {Observable<any>}
   */
  getTimeStatistics(start, end ,type): Observable<any> {
    return this.http.get(`${REQUEST.STATISTICS.ALL}`, {
      start, end, type
    });
  }
}
