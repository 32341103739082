import {Component, OnDestroy, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {BreadcrumbOption, NzBreadCrumbComponent, NzModalService} from 'ng-zorro-antd';
import {filter, takeUntil} from 'rxjs/operators';
import {Title} from '@angular/platform-browser';
import {Subject} from 'rxjs';

@Component({
  selector: 'app-bread',
  templateUrl: './bread.component.html',
  styleUrls: ['./bread.component.scss']
})
export class BreadComponent implements OnDestroy {
  @ViewChild('breadcrumb', { static: true })
  private breadcrumb: NzBreadCrumbComponent;
  private breadcrumbs: BreadcrumbOption[] | undefined = [];
  private destroy$ = new Subject();

  constructor(
    private router: Router,
    private modalService: NzModalService,
    private title: Title
  ) {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      takeUntil(this.destroy$)
    ).subscribe(() => {
      this.modalService.closeAll();
      setTimeout(() => this.changeTitle());
    });
  }

  changeTitle(): void {
    this.breadcrumbs = this.breadcrumb.breadcrumbs;
    if (this.breadcrumbs.length > 0) {
      this.title.setTitle('腾宇科技 - ' + this.breadcrumbs[this.breadcrumbs.length - 1].label);
    } else {
      this.title.setTitle('腾宇科技');
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
