import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {NzMessageService, NzModalService} from 'ng-zorro-antd';
import {Router} from '@angular/router';
import {StartupService} from '@core/service/startup.service';
import {mobileValidator} from '@shared/validations/validations';
import {BASE, REQUEST} from '@core/config/config';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {WebHttpUrlEncodingCodec} from '@shared/model/encoder';
import {DA_SERVICE_TOKEN, ITokenService} from '@delon/auth';
import {SettingService} from '@core/service/setting.service';
import {LoginBackMessage, User} from '@shared/model/baseModel';
import {MessageService} from '@core/service/message.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  interval$: any;
  type = 0;
  count = 0;
  force =0;

  constructor(
    private fb: FormBuilder,
    private modalService: NzModalService,
    private router: Router,
    private startupService: StartupService,
    private msg: NzMessageService,
    private http: HttpClient,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
    private setting: SettingService,
    private message: MessageService
  ) {
    this.loginForm = this.fb.group({
      username: [null, [Validators.required]],
      password: [null, Validators.required],
      mobile: [null, [Validators.required, mobileValidator]],
      captcha: [null, [Validators.required]]
    });
    this.modalService.closeAll();
  }

  ngOnInit() {
    this.message.close();
  }

  switch(ret: any) {
    this.type = ret.index;
  }

  getCaptcha() {
    if (this.loginForm.get('mobile').invalid) {
      this.loginForm.get('mobile').markAsDirty({onlySelf: true});
      this.loginForm.get('mobile').updateValueAndValidity({onlySelf: true});
      return;
    }
    this.count = 59;
    this.interval$ = setInterval(() => {
      this.count -= 1;
      if (this.count <= 0) {
        clearInterval(this.interval$);
      }
    }, 1000);
  }

  submit() {
    if (this.type === 0) {
      this.loginForm.get('username').markAsDirty();
      this.loginForm.get('username').updateValueAndValidity();
      this.loginForm.get('password').markAsDirty();
      this.loginForm.get('password').updateValueAndValidity();
      if (this.loginForm.get('username').invalid || this.loginForm.get('password').invalid) { return; }
    } else {
      this.loginForm.get('mobile').markAsDirty();
      this.loginForm.get('mobile').updateValueAndValidity();
      this.loginForm.get('captcha').markAsDirty();
      this.loginForm.get('captcha').updateValueAndValidity();
      if (this.loginForm.get('mobile').invalid || this.loginForm.get('captcha').invalid) { return; }
    }
    const loginForm = JSON.parse(JSON.stringify(this.loginForm.getRawValue()));
    const header = btoa(REQUEST.clientId + ':' + REQUEST.dummyClientSecret);
    const headers = new HttpHeaders()
      .set('Authorization', 'Basic ' + header)
      .set('Content-Type', 'application/x-www-form-urlencoded');
    const body = new HttpParams({ encoder: new WebHttpUrlEncodingCodec() })
      .set('grant_type', 'password')
      .set('scope', 'openid profile')
      .set('username', loginForm.username)
      .set('password', loginForm.password);
      let url = REQUEST.LOGIN;
    if(this.force === 1){
      url = '/uaa/oauth/token?force=1';
    }else{
      url = '/uaa/oauth/token';
    }
    this.http.post(BASE + url, body, {headers}).subscribe((res: any) => {
      if (!res.error) {
        const token = Object.assign(res, {token: res.access_token});
        this.tokenService.set(token);
        
        // Garbage interface 不能在startupService中同时返回用户及权限信息，只能在登录成功之后先行调用获取用户信息的接口。
        this.http.get(`${BASE}${REQUEST.USERINFO}`).subscribe(
          (data: any) => {
            if (!data.error) {
              const user = data as User;
              if (user.companyId === undefined || Number(user.companyId) > 0) {
                this.msg.error('非后台用户不能登录');
                this.tokenService.clear();
                return;
              }
              this.setting.setUser(user);
              this.startupService.load().then(() => {
                // tslint:disable-next-line:no-non-null-assertion
                let url = this.tokenService.referrer!.url || '/';
                if (url.includes('/passport') ||
                  url.includes('exception/404') ||
                  url.includes('exception/403') ||
                  url.includes('exception/500')
                ) {
                  url = '/';
                }
                this.router.navigateByUrl(url);
              });
            } else {
              this.msg.error('用户信息获取失败');
            }
          });
      } else {
        
        this.msg.error('用户名或密码错误');
      }
    }, (error: any) => {
      const data = error.error as (LoginBackMessage | any);
      //登录异常处理
      if (data.message.indexOf('已登录') > -1) {
        if (this.force === 1) {
          // 为了防止点击重新登录仍返回后台异常，所以限定，如果重新登录返回还是异常，就提示登录失败
          this.force = 0;
          this.msg.error('登录失败');
          return;
        } else {
          this.modalService.confirm({
            nzTitle: '登录提示',
            nzContent: data.message,
            nzOkText: '去登录',
            nzOkType: 'danger',
            nzOnOk: () => {
              this.force = 1;
              this.submit();
              return;
            },
            nzOnCancel: () => {
              this.msg.info('登录取消');
            }
          });
        }
      }
      if (!!data.error_description) {
        this.msg.error(error.error_description);
        return;
      }
      this.msg.error(data.message);
    });
  }

  ngOnDestroy(): void {
    if (!!this.interval$) {
      clearInterval(this.interval$);
    }
  }
}
