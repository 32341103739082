import {NgModule} from '@angular/core';
import {RoutesRoutingModule} from './routes-routing.module';
import {HomeComponent} from './home/home.component';
import {SharedModule} from '@shared/shared.module';
import {LoginComponent} from './passport/login/login.component';

@NgModule({
  imports: [
    SharedModule,
    RoutesRoutingModule
  ],
  declarations: [
    HomeComponent,
    LoginComponent
  ]
})

export class RoutesModule {
}
