import {Pipe, PipeTransform} from '@angular/core';
import * as IDValidator from 'id-validator';

export type infoType = 'addrCode' | 'birth' | 'sex';
@Pipe({
  name: 'idCardInfo'
})
export class IdCardInfoPipe implements PipeTransform {

  transform(value: any, type: infoType): string {
    const Validator = new IDValidator();
    const isIDCard: boolean = Validator.isValid(value, 18);
    if (isIDCard) {
      return Validator.getInfo(value)[type];
    } else {
      console.warn('IDCard info error');
    }
  }
}

/*
 * 号码有效时返回内容示例:
 * {
 *   'addrCode': 100101, //地址码信息,
 *   'addr':     '北京市东城区', //地址信息, 只在实例化时传入了GB2260时返回
 *   'birth':    '1988-01-20', //出生年月日
 *   'sex':      1, //性别，0为女，1为男
 *   'checkBit': 'X', //校验位，仅当18位时存在
 *   'length':   18 //身份证类型，15位或18位
 * }
 */
