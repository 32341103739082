import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UtilService } from '@core/service/util.service';
import { TrackInfo } from '@shared/model/baseModel';

@Component({
  selector: 'app-track',
  templateUrl: './track.component.html',
  styleUrls: ['./track.component.scss']
})
export class TrackComponent implements OnInit, OnChanges {
  @Input() modalType: string;
  @Input() trackList: TrackInfo[];
  @Input() BDSList: any[] = [];
  private points = [];
  private BDSPoints = [];
  private map: any;
  isShowMap = false;
  options: any = {};

  constructor(
    private util: UtilService,
  ) {
  }

  ngOnInit() {
    
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.modalType === 'map') {
      if (this.trackList.length > 0 || this.BDSList.length > 0) {
        this.isShowMap = this.trackList.length > 0 || this.BDSList.length > 0;
        if (this.map && this.BDSList.length > 0) {
          this.initMap(this.map);
        }
      }
    } else {
      this.isShowMap = false;
    }
  }

  initPoints(trackList, BDSList) {
    this.points = [];
    this.BDSPoints = [];
    console.log(this.trackList)
    if (trackList.length > 0) {
      trackList.map((track) => {
        const tPoint = new BMap.Point(track['longitude'], track['latitude']);
        this.points.push(tPoint);
      });
    }
    if (BDSList.length > 0) {
      BDSList.map(BDS => {
        const tPoint = new BMap.Point(BDS['longitude'], BDS['latitude']);
        this.BDSPoints.push(tPoint);
      });
    }
  }

  initMap(map: any) {
    this.map = map;
    if (this.isShowMap) {
      this.initPoints(this.trackList, this.BDSList);
    }
    map.enableScrollWheelZoom(true);
    map.addControl(new BMap.ScaleControl());
    if (this.points.length < 0 && this.BDSPoints.length < 0) {
      map.centerAndZoom(new BMap.Point(116.404, 39.915), 11);
    }
    // 手机GPS定位轨迹
    const startIcon = new BMap.Icon('assets/img/start.png', new BMap.Size(35, 35));
    const endIcon = new BMap.Icon('assets/img/end.png', new BMap.Size(35, 35));
    if (this.points.length > 0) {
      map.centerAndZoom(this.points[0], 6);
      map.addOverlay(new BMap.Polyline(this.points, { strokeColor: 'black', strokeWeight: 5, strokeOpacity: 1 }));
      map.setViewport(this.points);
      const start = new BMap.Marker(this.points[0], { icon: startIcon });
      const end = new BMap.Marker(this.points[this.points.length - 1], { icon: endIcon });
      map.addOverlay(start);
      map.addOverlay(end);
      const starttime = this.util.dateFormat(this.trackList[0].gpsTime, 'YYYY-MM-DD hh:mm:ss');
      const endtime = this.util.dateFormat(this.trackList[this.trackList.length - 1].gpsTime, 'YYYY-MM-DD hh:mm:ss');
      const startAddress = this.trackList[0].address ? this.trackList[0].address : '';
      const endAddress = this.trackList[this.trackList.length - 1].address ? this.trackList[this.trackList.length - 1].address : '';
      start.setLabel(new BMap.Label(`GPS起点:${startAddress} 时间:${starttime}`, { offset: new BMap.Size(30, -10) }));
      end.setLabel(new BMap.Label(`GPS终点:${endAddress} 时间:${endtime}`, { offset: new BMap.Size(30, -10) }));
    }
    // 北斗定位轨迹
    if (this.BDSPoints.length > 0) {
      map.centerAndZoom(this.BDSPoints[0], 6);
      map.addOverlay(new BMap.Polyline(this.BDSPoints, { strokeColor: 'red', strokeWeight: 3, strokeOpacity: 1 }));
      map.setViewport(this.BDSPoints);
      const start = new BMap.Marker(this.BDSPoints[0], { icon: startIcon });
      const end = new BMap.Marker(this.BDSPoints[this.BDSPoints.length - 1], { icon: endIcon });
      map.addOverlay(start);
      map.addOverlay(end);
      const starttime = this.util.dateFormat(this.BDSList[0].gpsTime, 'YYYY-MM-DD hh:mm:ss');
      const endtime = this.util.dateFormat(this.BDSList[this.BDSList.length - 1].gpsTime, 'YYYY-MM-DD hh:mm:ss');
      const startAddress = this.BDSList[0].address ? this.BDSList[0].address : '';
      const endAddress = this.BDSList[this.trackList.length - 1].address ? this.BDSList[this.trackList.length - 1].address : '';
      start.setLabel(new BMap.Label(`北斗起点:${startAddress}时间:${starttime}`, { offset: new BMap.Size(30, -10) }));
      end.setLabel(new BMap.Label(`北斗终点:${endAddress}时间:${endtime}`, { offset: new BMap.Size(30, -10) }));
    }
  }
}
