import { Component, ElementRef, OnInit } from '@angular/core';
import { getTimeDistance } from '@delon/util';
import { HomeService } from './service/home.service';
import { UtilService } from '@core/service/util.service';
import { getISOWeek } from 'date-fns';
import { BackRes } from '@shared/model/baseModel';

export class CardValues {
  title: string;
  value: string;
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  cardValues: CardValues[] = [];
  dateRange: Date[] = [];
  rankingListData: any = [];
  current: string;
  private list: { [key: string]: any }[] = [];
  private chart: any;
  constructor(
    private homeService: HomeService,
    private util: UtilService
  ) {}

  ngOnInit(): void {
    this.setDate('today');
  }

  setDate(type: any) {
    this.dateRange = getTimeDistance(type);
    this.current = type;
    switch (type) {
      case 'today':
        this.homeService.getStatistics(1).subscribe((res: BackRes) => {
          if (!res.error) {
            this.setValue(res.data.top, res.data.left);
            const list = res.data.right as Array<any>;
            this.rankingListData = list.filter((item, index) => index < 8);
          }
        });
        break;
      case 'week':
        this.homeService.getStatistics(2).subscribe((res: BackRes) => {
          if (!res.error) {
            this.setValue(res.data.top, res.data.left);
            const list = res.data.right as Array<any>;
            this.rankingListData = list.filter((item, index) => index < 8);
          }
        });
        break;
      case 'month':
        this.homeService.getStatistics(3).subscribe((res: BackRes) => {
          if (!res.error) {
            this.setValue(res.data.top, res.data.left);
            const list = res.data.right as Array<any>;
            this.rankingListData = list.filter((item, index) => index < 8);
          }
        });
        break;
      default:
        break;
    }
  }

  render(el: ElementRef) {
    this.chart = new G2.Chart({
      container: el.nativeElement,
      forceFit: true,
      height: 300,
      padding: [20, 20, 30, 90]
    });
    this.chart.source(this.list, {
      value: {
        tickCount: 5
      }
    });
    this.chart.scale('result', {
      alias: '交易额'
    });
    this.chart.axis('time', {
      label: {
        textStyle: {
          fill: '#aaaaaa'
        }
      },
      tickLine: {
        alignWithLabel: false,
        length: 0
      }
    });
    this.chart.axis('result', {
      label: {
        textStyle: {
          fill: '#aaaaaa'
        },
        formatter: function formatter(text) {
          return text.replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
        }
      },
      title: {
        offset: 70
      }
    });
    this.chart.tooltip({
      share: true
    });
    this.chart.interval().position('time*result').opacity(1);
    this.chart.render();
  }

  setValue(obj: { [key: string]: string }, list: { [key: string]: string }[]) {
    this.cardValues = [
      {
        title: '订单总额',
        value: obj.money
      },
      {
        title: '订单量',
        value: obj.count
      },
      {
        title: '支付笔数',
        value: obj.paycount
      },
      {
        title: '识别成功率',
        value: '78%'
      }
    ];
    if (!!list) {
      this.list = list;
    } else {
      this.list = [];
    }
    this.chart.changeData(this.list);
  }

  dateChange(times: Date[]) {
    this.current = '';
    if (times.length > 0) {
      const start = this.util.dateFormat(times[0], 'YYYY-MM-DD');
      const end = this.util.dateFormat(times[1], 'YYYY-MM-DD');
      this.homeService.getTimeStatistics(start, end, 4).subscribe((res: BackRes) => {
        if (!res.error) {
          this.setValue(res.data.top, res.data.left);
        }
      });
    } else {
      this.setDate('today');
    }
  }

}
