import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {NzMessageService, UploadChangeParam, UploadFile} from 'ng-zorro-antd';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {REQUEST} from '@core/config/config';
import { Observable, Observer } from 'rxjs';

export const DEFAULT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => ImportFileComponent),
  multi: true
};

@Component({
  selector: 'app-import-file',
  templateUrl: './import-file.component.html',
  styleUrls: ['./import-file.component.scss'],
  providers: [DEFAULT_VALUE_ACCESSOR]
})
export class ImportFileComponent implements OnInit,ControlValueAccessor {
  @Input() uploadUrl: string;
  @Input() isHand: boolean = false;
  @Input() isBtn: boolean = false;
  @Input() buttonText: string;
  defaultUploadUrl = REQUEST.UPLOAD_URL;
  fileList: UploadFile[] = [];
  value: any = null;
  name: string = null;
  // propagateChange = (_: any) => { };
  @Output() propagateChange: EventEmitter<any> = new EventEmitter();

  constructor(
    private msg: NzMessageService
  ) { 
   
  }

  writeValue(value: any): void {
    if (!!value) {
      this.value = value;
      this.fileList = this.fileList.concat(value);
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  ngOnInit(): void {
    this.defaultUploadUrl = this.uploadUrl || this.defaultUploadUrl;
    
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  beforeUpload = (file: UploadFile): Observable<any> => {
    return new Observable((observer: Observer<boolean>) => {
      const isXlsx = file.name.indexOf('xlsx') > -1 || file.name.indexOf('xls') > -1;
        if (!isXlsx) {
          this.msg.error('您只能上传xlsx或xls格式文件!');
          observer.complete();
          return;
        }
        if(this.isHand){
          this.fileList = this.fileList.concat(file);
          this.name = file.name;
          return;
        }
       
        observer.next(isXlsx);
        observer.complete();
      });
    // return true;
  }

  fileChange(params: UploadChangeParam) {
    if (params.file.status === 'done') {
      this.value = params.file;
      this.name = params.file.name;
      // this.propagateChange.emit(params.file);
    } else if (params.file.status === 'error') {
      this.msg.error(params.file.error.error.message);
    }
    this.propagateChange.emit(this.value);
  }

}
