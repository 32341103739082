import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { UtilService } from 'src/app/core/service/util.service';
import { ACLService } from '@delon/acl';

@Component({
  selector: 'app-batch-operate-list',
  templateUrl: './batch-operate-list.component.html',
  styleUrls: ['./batch-operate-list.component.scss']
})
export class BatchOperateListComponent implements OnInit {
  
  @Input() list:Array<{id: string, name: string, operateType: string,url:string,hide:boolean}>;
  @Output() propagateChangeData: EventEmitter<any> = new EventEmitter();

  constructor(
    private util: UtilService,
    private aclService: ACLService
  ) { }

  ngOnInit() {
    // this.list.map(item =>{
    //   item.hide = !!item.hide;
    //   if(item.hide === false && item.id && this.aclService.canAbility(item.id)){
    //     item.hide = true;
    //   }
    // });
    this.list.filter((item) => this.aclService.canAbility(item.id))
  }

  download(item){
    this.util.exportExcel('',item.downloadurl);
  }

  propagateChange(e){
    this.propagateChangeData.emit(e)
  }

}