import { Inject, Injectable, Renderer2, RendererFactory2, TemplateRef } from '@angular/core';
import { BaseForm } from '@shared/model/baseForm';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzMessageService, NzModalRef, NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '@core/service/http.service';
import { BASE, REQUEST } from '@core/config/config';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { format } from 'date-fns';
import { Actions, GridParams } from '@shared/model/baseModel';
import { QueryGridComponent } from '@shared/component/query-grid/query-grid.component';
import { ImportFileComponent } from '@shared/component/import-file/import-file.component';
import { ACLService } from '@delon/acl';
import { SettingService } from '@core/service/setting.service';
import { DOCUMENT } from '@angular/common';
import { TempPreviewComponent } from '@shared/component/temp-preview/temp-preview.component';

@Injectable({
  providedIn: 'root'
})
export class UtilService {
  private renderer2: Renderer2;

  constructor(
    private fb: FormBuilder,
    private msg: NzMessageService,
    private router: Router,
    private http: HttpService,
    private modalService: NzModalService,
    private aclService: ACLService,
    private setting: SettingService,
    private notification: NzNotificationService,
    private rendererFactory: RendererFactory2,
    @Inject(DOCUMENT) private doc: Document
  ) {
    this.renderer2 = rendererFactory.createRenderer(null, null);
  }

  /**
   * 用于校验 <ng-content> 是否为空，自定义组件是可以使用
   * @param element
   * @returns {boolean}
   */
  isEmpty = (element: HTMLElement): boolean => {
    const nodes = element.childNodes;
    for (let i = 0; i < nodes.length; i++) {
      const node = nodes.item(i);
      if (node.nodeType === 1 && (node as HTMLElement).outerHTML.toString().trim().length !== 0) {
        return false;
      } else if (node.nodeType === 3 && node.textContent.toString().trim().length !== 0) {
        return false;
      }
    }
    return true;
  }

  /**
   * 将表单JSON转为FormGroup表单
   * @param baseForms
   * @returns {FormGroup}
   */
  toFormGroup(baseForms: BaseForm<any>[]): FormGroup {
    const group: any = {};
    baseForms.map(baseForm => {
      group[baseForm.key] = [
        { value: baseForm.value, disabled: baseForm.disabled },
        baseForm.required ? [Validators.required].concat(baseForm.validators) : baseForm.validators,
        baseForm.asyncValidators
      ];
    });
    return this.fb.group(group);
  }

  /**
   * 单选校验
   * @param grid
   * @param msg
   * @returns {Promise<any>}
   */
  singleSelect(grid: any, msg?: string): Promise<any> {
    const selects = grid.getFieldList();
    return new Promise((resolve, reject) => {
      if (selects.length === 1) {
        return resolve(selects[0]);
      } else {
        this.msg.warning(msg ? msg : '请选择一条要操作的记录');
        // return reject();
      }
    });
  }

  /**
   * 多选校验
   * @param grid
   * @param msg
   * @returns {Promise<any>}
   */
  multipleSelect(grid: any, msg?: string): Promise<any> {
    const selects = grid.getFieldList();
    return new Promise((resolve, reject) => {
      if (selects.length > 0) {
        return resolve(selects);
      } else {
        this.msg.warning(msg ? msg : '请选择要操作的记录');
        // return reject();
      }
    });
  }

  /**
   * 页面回退
   * @param value
   * @param route
   */
  back(value: number, route: ActivatedRoute): void {
    const backUrl = new Array<string>(value).fill('../').join('');
    this.router.navigate([backUrl], { relativeTo: route });
  }

  /**
   * 获取数据字典
   * @param url
   * @param type
   * @returns {Observable<any>}
   */
  getDic(url: string, type: string): Observable<any> {
    if (type === 'CAR_VERIFY_ERROR') {
      return this.http.get(url, { type }).pipe(
        map(res => res.content),
        catchError(res => of([]))
      );
    } else {
      return this.http.get(url, { type }).pipe(
        map(res => {
          const options = res.data;
          options.forEach(item => {
            if (type === '0004') {
              if (item.text === '汽油') {
                item.isDisabled = true;
              }
            }
          });
          return options;
        }),
        catchError(res => of([]))
      );
    }
  }

  getBankDic(url: string, type: string): Observable<any> {
    return this.http.get(url, { type }).pipe(
      map(res => {
        const options = res.data;
        options.forEach(item => {
          const value = item.value;
          item.value = item.text;
          item.code = value;
        });
        return options;
      }),
      catchError(res => of([]))
    );
  }

  /**
   * 过滤空对象
   * @param object
   * @returns {Dictionary<any>}
   */
  baseSearchFilter(object: object): object {
    return _.omitBy(object, _.isNil);
  }

  /**
   * 弹框表格，支持双击事件
   * @param title
   * @param titleAction
   * @param queryGridParams
   * @param rowDbClick
   * @returns {NzModalRef<QueryGridComponent>}
   */
  queryGridWin(
    title: string,
    titleAction: string | TemplateRef<void>,
    queryGridParams: GridParams,
    rowDbClick?: (e) => void
  ): NzModalRef {
    return this.modalService.create({
      nzTitle: title,
      nzContent: QueryGridComponent,
      nzWidth: 850,
      nzComponentParams: {
        titleAction,
        queryGridParams,
        rowDbClick
      },
      nzFooter: null,
      nzMaskClosable: false,
      nzClosable: true
    });
  }

  /**
   * 文件导入
   * @param title
   * @param uploadUrl
   * @returns {Promise<any>}
   */
  importFile(title: string = '文件上传', uploadUrl?: string, isHand?: boolean, opetate?: () => void): Promise<any> {
    return new Promise((resolve, reject) => {
      const modal = this.modalService.create({
        nzTitle: title,
        nzContent: ImportFileComponent,
        nzMaskClosable: false,
        nzClosable: true,
        nzComponentParams: {
          uploadUrl,
          isHand: isHand
        },
        nzFooter: [
          {
            label: '下载模板',
            type: 'primary',
            onClick: () => opetate()
          },
          {
            label: '确定',
            type: 'primary',
            disabled: com => !com.fileList[0],
            onClick: com => {
              resolve(com);
              modal.close();
            }
          }
        ]
      });
    });
  }

  /**
   * 确认框
   * @param title
   * @returns {Promise<any>}
   */
  submitConfirm(title: string, info = ''): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.modalService.confirm({
        nzTitle: title + '提示：',
        nzContent: '确定要' + title + info + '？',
        nzOkText: title,
        nzOkType: 'danger',
        nzOnOk: () => resolve(),
        nzOnCancel: () => this.msg.info(title + '操作取消')
      });
    });
  }

  /**
   * 时间格式化
   * @param value
   * @param formatString
   */
  dateFormat = (value, formatString: string = 'YYYY-MM-DD HH:mm') => {
    return !!value ? format(value, formatString) : null;
  }

  /**
   * 根据权限点过滤表格操作列按钮
   * @param actions
   * @returns {Actions[]}
   */
  showActionsColumns(actions: Actions[]): Actions[] {
    return this.setting.getUser.isAdmin ?
      actions : actions.filter((item: Actions) => this.aclService.canAbility(item.id));
  }

  /**
   * 图片预加载
   * @param path
   * @returns {Promise<any>}
   */
  preloadImage(path: string): Promise<any> {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = resolve;
      image.onerror = reject;
      image.src = path;
    });
  }

  /**
   * 获取上个月月份
   * 
   */
  getLastMonth(nowMonth?: boolean) {
    const date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    if (month == 0) {
      year = year - 1;
      month = 12;
    }
    if (nowMonth) {
      return year + "年" + (month + 1) + "月";
    } else {
      return year + "年" + month + "月";
    }
  }

  exportExcel(url: string, oturl?: string): void {
    console.log(oturl)
    const download = this.renderer2.createElement('a');
    this.renderer2.setAttribute(download, 'id', 'downExcel');
    this.renderer2.setAttribute(download, 'download', '');
    this.renderer2.setAttribute(download, 'display', 'none');
    if (!!url) {
      this.renderer2.setAttribute(download, 'href', REQUEST.EXPORT + url);
    } else {
      this.renderer2.setAttribute(download, 'href', REQUEST.DISPLAY_PIC_BASE + oturl);
    }
    this.renderer2.appendChild(this.doc.body, download);
    download.click();
    this.renderer2.removeChild(this.doc.body, download);
  }

  goOtherSystem(url: string): void {
    console.log(url);
    const download = this.renderer2.createElement('a');
    this.renderer2.setAttribute(download, 'display', 'none');
    this.renderer2.setAttribute(download, 'target', 'blank');
    this.renderer2.setAttribute(download, 'href', url);
    this.renderer2.appendChild(this.doc.body, download);
    download.click();
    this.renderer2.removeChild(this.doc.body, download);
  }

  handleVerifyData(data, list) {
    const keyValueInfoList: Array<{ key: string; value: string }> = [];
    for (let key in data) {
      let value = data[key];
      for (let i in list) {
        let reg = eval("/" + list[i].key + "/gi");
        value = value.replace(reg, list[i].value);
      }
      keyValueInfoList.push({
        key: key,
        value: value,
      });
    }
    return keyValueInfoList;
  }

  /**
   * @param verifyInfoList
   * @param verifyTemplate
   */
  noticeModal(
    verifyTemplate: TemplateRef<{}>,
    verifyInfoList: Array<{ key: string, value: string }>,
  ) {
    this.notification.template(verifyTemplate, {
      nzData: verifyInfoList,
      nzDuration: 0,
      nzStyle: {
        width: '688px',
        marginLeft: '-280px',
        padding: '0'
      }
    });
  }

  handleErrorMsg(msg, list) {
    for (let i in list) {
      let reg = eval("/" + list[i].key + "/gi");
      msg = msg.replace(reg, list[i].value);
    }
    return msg;
  }

  //过滤掉字符串方括号
  handleData(data: any) {
    const finaldata = data.replace(/\[|\]|【|】|\"|\'/g, '');
    return finaldata;
  }

  /**
   * 获取日期时间戳
   * @param {Number} dayCount 选择日期的前几天或者后几天
   */
  getDateStr(selecDay: Date, dayCount: number) {
    let dd = new Date()
    dd.setDate(selecDay.getDate() + dayCount)
    let time = dd.getTime()
    return time
  }

  /**
   * 获取城市字典
   * @param url
   * @param type
   * @returns {Observable<any>}
   */
  getAreaDic(url: string, type?: string): Observable<any> {
    if (type === 'CAR_VERIFY_ERROR') {
      return this.http.get(url, { type }).pipe(
        map(res => res.content),
        catchError(res => of([]))
      );
    } else {
      return this.http.get(url).pipe(
        map(res => {
          const options = res.data;
          return options;
        }),
        catchError(res => of([]))
      );
    }
  }

  getUrl(type) {
    switch (type) {
      case '00059':
        return REQUEST.ORDERS.HNADDRESS;
      case '00060':
        return REQUEST.ORDERS.TJADDRESS;
      case '00554':
        return REQUEST.ORDERS.JCADDRESS;
    }
  }

  getFormatTime(str) {
    return str.substring(0, 2) + ':' + str.substring(2, 4) + ':' + str.substring(4, 6)
  }

  /**
   * 模板预览
   * @param content
   * @returns {NzModalRef}
   */
  articlePreview(content: string): NzModalRef {
    const modal: NzModalRef = this.modalService.create({
      nzTitle: '模板预览',
      nzContent: TempPreviewComponent,
      nzMaskClosable: false,
      nzClosable: true,
      nzWidth: 850,
      nzComponentParams: {
        content
      },
      nzFooter: [
        {
          label: '取消',
          onClick: () => modal.destroy()
        }
      ]
    });
    return modal;
  }

  jumpPage(data): void {
    const download = this.renderer2.createElement('a');
    this.renderer2.setAttribute(download, 'target', '_blank');
    this.renderer2.setAttribute(download, 'display', 'none');
    this.renderer2.setAttribute(download, 'href', data.ePolicyUrl);
    this.renderer2.appendChild(this.doc.body, download);
    download.click();
    this.renderer2.removeChild(this.doc.body, download);
  }
}
