import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NzMessageService} from 'ng-zorro-antd';
import { UtilService } from '@core/service/util.service';

@Component({
  selector: 'app-condition-export',
  templateUrl: './condition-export.component.html',
  styleUrls: ['./condition-export.component.scss']
})
export class ConditionExportComponent implements OnInit {
  @Input() exportConFormJson: Array<any>;
  exportConForm: FormGroup;
  // exportConFormJson: Array<any>;

  constructor(
    private util: UtilService,
    private msg: NzMessageService
  ) {
  }

  ngOnInit(): void {
    // this.exportConFormJson = this.conFormJson;
    this.exportConForm = this.util.toFormGroup(this.exportConFormJson);
  }

}
