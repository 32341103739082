import { Injectable } from '@angular/core';
import {Observable, of} from 'rxjs';
import {catchError, map} from 'rxjs/internal/operators';
import {NzMessageService} from 'ng-zorro-antd';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BASE} from '../config/config';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private baseUrl: string = BASE;
  private headers = new HttpHeaders();

  constructor(
    private httpClient: HttpClient,
    private msg: NzMessageService
  ) {
    this.headers = this.headers.set('Accept', 'application/x-spring-data-verbose+json');
  }

  // 查询
  get(url: string, params?: any): Observable<any> {
    const requestUrl = this.baseUrl + url;
    return this.httpClient.get(requestUrl, {
      headers: this.headers,
      params
    }).pipe(
      catchError(this.handleError()),
      map(this.backFormat())
    );
  }

  // 删除
  remove(url: string, params?: any): Observable<any> {
    const requestUrl = this.baseUrl + url;
    return this.httpClient.delete(requestUrl, {
      headers: this.headers,
      params
    }).pipe(
      catchError(this.handleError()),
      map(this.backFormat())
    );
  }

  // 新增
  post(url: string, body: any, params?: any): Observable<any> {
    const requestUrl = this.baseUrl + url;
    return this.httpClient.post(requestUrl, body, {
      headers: this.headers,
      params
    }).pipe(
      catchError(this.handleError()),
      map(this.backFormat())
    );
  }

  // 同步
  sync(url: string, params?: any): Observable<any> {
    const requestUrl = this.baseUrl + url;
    return this.httpClient.post(requestUrl, {
      headers: this.headers,
      params
    }).pipe(
      catchError(this.handleError()),
      map(this.backFormat())
    );
  }

  // 修改
  put(url: string, body: any, params?: any): Observable<any> {
    const requestUrl = this.baseUrl + url;
    return this.httpClient.put(requestUrl, body, {
      headers: this.headers,
      params
    }).pipe(
      catchError(this.handleError()),
      map(this.backFormat())
    );
  }

  // 部分修改
  patch(url: string, body: any, params?: any): Observable<any> {
    const requestUrl = this.baseUrl + url;
    return this.httpClient.patch(requestUrl, body, {
      headers: this.headers,
      params
    }).pipe(
      catchError(this.handleError()),
      map(this.backFormat())
    );
  }

  // 导入
  postUpload(url: string, body: any, params?: any): Observable<any> {
    const requestUrl = url;
    return this.httpClient.post(requestUrl, body, {
      params
    }).pipe(
      catchError(this.handleError()),
      map(this.backFormat())
    );
  }

  private handleError<T>() {
    return (error: any): Observable<T> => {
      this.msg.error(error.error.message);
      return of({error: true, message: error.error.message} as unknown as T);
    };
  }

  private backFormat = () => {
    // 处理特殊情况
    return (res: any): object => {
      return !!res ? res : {error: false};
    };
  }
}
