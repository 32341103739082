import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-temp-preview',
  templateUrl: './temp-preview.component.html',
  styleUrls: ['./temp-preview.component.scss']
})
export class TempPreviewComponent implements OnInit {
  @Input() content: string = null;
  constructor() { }

  ngOnInit() {

  }

}
