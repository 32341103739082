import { Injectable, Injector, Inject } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponseBase
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs/internal/observable/of';
import { NzNotificationService, NzMessageService, NzModalService } from 'ng-zorro-antd';
import { Router } from '@angular/router';
import { DA_SERVICE_TOKEN, ITokenService } from '@delon/auth';

const CODE_MESSAGE = {
  200: '服务器成功返回请求的数据。',
  201: '新建或修改数据成功。',
  202: '一个请求已经进入后台排队（异步任务）。',
  204: '删除数据成功。',
  400: '发出的请求有错误，服务器没有进行新建或修改数据的操作。',
  401: '用户没有权限（令牌、用户名、密码错误）。',
  403: '用户得到授权，但是访问是被禁止的。',
  404: '发出的请求针对的是不存在的记录，服务器没有进行操作。',
  406: '请求的格式不可得。',
  410: '请求的资源被永久删除，且不会再得到的。',
  422: '当创建一个对象时，发生一个验证错误。',
  500: '服务器发生错误，请检查服务器。',
  502: '网关错误。',
  503: '服务不可用，服务器暂时过载或维护。',
  504: '网关超时。',
};

@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private msg: NzMessageService,
    private modalService: NzModalService,
    @Inject(DA_SERVICE_TOKEN) private tokenService: ITokenService,
  ) {
  }

  private get notification(): NzNotificationService {
    return this.injector.get(NzNotificationService);
  }

  private goTo(url: string) {
    setTimeout(() => this.injector.get(Router).navigateByUrl(url));
  }

  private checkStatus(ev: HttpResponseBase) {
    if ((ev.status >= 200 && ev.status < 300) || ev.status === 401) {
      return;
    }

    // const errortext = CODE_MESSAGE[ev.status] || ev.statusText;
    // this.notification.error(`请求错误 ${ev.status}: ${ev.url}`, errortext);
  }

  /**
   * 状态码通用处理（由于后端状态码使用混乱，可能没什么卵用）
   * @param ev
   * @returns {Observable<never> | Observable<HttpResponseBase>}
   */
  private handleData(ev: HttpResponseBase): Observable<any> {
    this.checkStatus(ev);
    // 业务处理：一些通用操作
    switch (ev.status) {
      case 200:
        // 业务层级错误处理(由于后端restful没有一套统一输出格式，所以也没什么卵用)
        break;
      // case 401:
      //   this.notification.error(`未登录或登录已过期，请重新登录。`, ``);
      //   // 清空 token 信息
      //   (this.injector.get(DA_SERVICE_TOKEN) as ITokenService).clear();
      //   this.goTo('/passport/login');
      //   break;
      case 403:
      case 404:
      case 500:
        this.goTo(`/exception/${ev.status}`);
        break;
      // case 401:
      //   this.modalService.confirm({
      //     nzTitle: '登陆提示',
      //     nzContent: '未登录或登录已过期,是否重新登录？',
      //     nzOkText: '去登录',
      //     nzOkType: 'danger',
      //     nzOnOk: () => {
      //       (this.injector.get(DA_SERVICE_TOKEN) as ITokenService).clear();
      //       this.goTo('/passport/login');
      //       return;
      //     },
      //     nzOnCancel: () => {
      //       this.msg.info('重新登录取消');
      //     }
      //   });
      //   break;
      default:
        if (ev instanceof HttpErrorResponse) {
          console.warn('未可知错误，大部分是由于后端不支持CORS或无效配置引起', ev);
          return throwError(ev);
        }
        break;
    }
    return of(ev);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // const secureReq = req.clone({
    //   url: req.url.replace('http://', 'https://')
    // });
    return next.handle(req).pipe(
      mergeMap((event: any) => {
        if (event instanceof HttpResponseBase) {
          return this.handleData(event);
        }
        return of(event);
      }),
      catchError((error: HttpErrorResponse) => {
        if (req.url.indexOf('/uaa/oauth/token') === -1) {
          return this.handleData(error);
        } else {
          return throwError(error);
        }
      })
    );
  }
}
