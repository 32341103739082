import {Component, Input, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BaseGridComponent} from '@shared/component/base-grid/base-grid.component';
import {GridParams} from '@shared/model/baseModel';

@Component({
  selector: 'app-query-grid',
  templateUrl: './query-grid.component.html',
  styleUrls: ['./query-grid.component.scss']
})
export class QueryGridComponent implements OnInit {
  @Input() queryGridParams: GridParams;
  @Input() titleAction: string | TemplateRef<void>;
  @Input() rowDbClick: (e) => void;
  @ViewChild('queryGrid', { static: true }) queryGrid: BaseGridComponent;
  constructor() { }

  ngOnInit() {
  }

  queryGridRowDbClick(e) {
    this.rowDbClick(e);
  }

}
