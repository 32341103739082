import {Component} from '@angular/core';
import {ICellRendererAngularComp} from 'ag-grid-angular';
import {Actions} from '@shared/model/baseModel';


@Component({
  selector: 'app-actions-renderer',
  template: `
    <a nz-tooltip *ngFor="let action of actions"
       appDebounceClick
       [nzTooltipTitle]="action.name"
       (debounceClick)="_click(action)"
       [acl]="action.id"
    >
      {{action.name}}
    </a>
  `,
  styles: [
    `
      a:not(:first-child) {
        padding: 0 6px;
      }
    `
  ]
})
export class ActionsRendererComponent implements ICellRendererAngularComp {

  actions: Actions[];
  private params: any;
  constructor() { }

  agInit(params: any): void {
    this.params = params;
    this.actions = params.actions;
  }

  refresh(params: any): boolean {
    return false;
  }

  _click(action: Actions) {
    // 在执行回调之前先选中
    this.params.node.setSelected(true, true);
    action.click();
  }

}
