import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {UtilService} from '@core/service/util.service';

export type ExceptionType = 403 | 404 | 500;

@Component({
  selector: 'exception',
  templateUrl: './exception.component.html',
  styleUrls: ['./exception.component.scss']
})

export class ExceptionComponent implements OnInit {
  @ViewChild('conTpl', { static: true })
  private conTpl: ElementRef;

  _type: ExceptionType;
  hasCon = false;

  _img = '';
  _title = '';
  _desc = '';

  @Input()
  set type(value: ExceptionType) {
    const item = {
      403: {
        img: 'https://gw.alipayobjects.com/zos/rmsportal/wZcnGqRDyhPOEYFcZDnb.svg',
        title: '403',
        desc: '抱歉,您无权访问该页面!'
      },
      404: {
        img: 'https://gw.alipayobjects.com/zos/rmsportal/KpnpchXsobRgLElEozzI.svg',
        title: '404',
        desc: '抱歉,您访问的页面不存在!'
      },
      500: {
        img: 'https://gw.alipayobjects.com/zos/rmsportal/RVRUAYdCGeYNBWoKiIwB.svg',
        title: '500',
        desc: '抱歉,服务器出错了!'
      },
    }[value];

    if (!item) {
      return;
    }

    this._type = value;
    this._img = item.img;
    this._title = item.title;
    this._desc = item.desc;
  }

  @Input()
  set img(value: string) {
    this._img = value;
  }

  @Input()
  set title(value: string) {
    this._title = value;
  }

  @Input()
  set desc(value: string) {
    this._desc = value;
  }

  constructor(private util: UtilService) {
  }

  ngOnInit() {
    this.checkContent();
  }

  checkContent() {
    this.hasCon = !this.util.isEmpty(this.conTpl.nativeElement);
  }

  back() {
    window.history.back();
  }
}
