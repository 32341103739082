import {Component, OnDestroy, OnInit, ViewChild, TemplateRef, Input} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {UtilService} from '@core/service/util.service';
import {NzMessageService, NzNotificationService, NzModalService} from 'ng-zorro-antd';
import {switchMap, takeUntil} from 'rxjs/operators';
import {FormGroup, Validators} from '@angular/forms';
import {OrderDetailService} from './service/order-detail.service';
import {GridColumnService} from '@core/service/grid-column.service';
import {BackRes, Actions, Dic} from '@shared/model/baseModel';
import {SettingService} from '@core/service/setting.service';
import {REQUEST, freightType } from '@core/config/config';
import {BaseGridComponent} from '@shared/component/base-grid/base-grid.component';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss']
})
export class OrderDetailComponent implements OnInit {
  // 运单详情
  @ViewChild('orderDetail', { static: true })
  private orderDetail: TemplateRef<{}>;
  orderInfoList: Array<{ key: string, value: string }>;
  orderImgInfoList: Array<{ key: string, value: string }>;
  orderCodeInfo: any;
  orderTypeOptions: Dic[] = [];
  goodsTypeOptions: Dic[] = [];
  cargoTypeOptions: Dic[] = [];
  actions: Actions[];
  @ViewChild(BaseGridComponent, { static: false })
  @Input() model:any
  private grid: BaseGridComponent;
  baseInfoTitle: string = '运单详情'
  CargoTypeDic: Dic[] = [];

  constructor( 
    private orderDetailService: OrderDetailService,
    private route: ActivatedRoute,
    private util: UtilService,
    private router: Router,
    private msg: NzMessageService,
    private gridColumn: GridColumnService,
    private setting: SettingService,
    private notification: NzNotificationService,
    private modalService: NzModalService) 
    { 
      this.getDetailSwichData();
    }

  ngOnInit() {
    this.checkDetail();
  }

  checkDetail = () => {
    
    // this.util.singleSelect(this.grid).then(model => {
      this.orderCodeInfo = this.model;
      this.orderInfoList = this.orderDetailService.getOrderInfo;
      this.orderImgInfoList = this.orderDetailService.getOrderImgInfo;
      // this.orderDetailService.orderDetail(this.model.did).subscribe((res: BackRes) => {
      //   if (!res || !res.isError) {
      //     this.orderCodeInfo = res;
      //     this.openOrderCodeDetail();
      //   }
      // })
    // })
  }

  /**
   * 获取详情特殊字段的字典
   */
  getDetailSwichData = () => {
    this.util.getDic(REQUEST.ORDERS.DIC,'0010').subscribe((res: any) => {
      if (!res.error) {
        this.orderTypeOptions = res;
      }
    });
    this.util.getDic(REQUEST.ORDERS.DIC,'0016').subscribe((res: any) => {
      if (!res.error) {
        this.cargoTypeOptions = res;
      }
    });
    this.goodsTypeOptions = freightType;
  }

  /**
   * 打开运单详情model
   */
  // openOrderCodeDetail():void{
  //   this.modalService.create({
  //     nzTitle: '运单详情',
  //     nzContent: this.orderDetail,
  //     nzMaskClosable: true,
  //     nzClosable: true,
  //     nzWidth: 1500,
  //     nzFooter: null
  //   });
  // }


  /**
   * 特殊信息渲染
   * @param key
   * @returns {string | any}
   */
  rendererInfo = (key: string): string => {
    if (key === 'sync') {
      let option = this.orderTypeOptions.filter(item => item.code === this.orderCodeInfo[key]);
      return option.length > 0 ? option[0].text : '';
    }
    if (key === 'goodsPropertyTypeCode') {
      let option = this.goodsTypeOptions.filter(item => item.code == this.orderCodeInfo[key]);
      return option.length > 0 ? option[0].value : '';
    }
    if (key === 'cargoType') {
      let option = this.cargoTypeOptions.filter(item => item.value == this.orderCodeInfo[key]);
      return option.length > 0 ? option[0].text : '';
    }
    if (key === 'cargoNum' || key === 'cargo') {
      return this.orderCodeInfo[key] + this.orderCodeInfo['cargUnit'];
    }
    if(key === 'unitWight'){
      if(this.orderCodeInfo[key]){
        return this.orderCodeInfo[key] + '千克';
      }
    }
    if (key === 'cash' || key === 'orderMoney' || key === 'prepayment' || key === 'receiptDeposit' || key === 'etcMoney' || key === 'refuelCardMoney') {
      return this.orderCodeInfo[key] + '元';
    }
    if (key === 'dcollection') {
      return this.orderCodeInfo[key] === 1 ? '是' : '否';
    }
    return this.orderCodeInfo[key];
  }
}
