import {Component, Input, forwardRef} from '@angular/core';
import {NzMessageService, UploadFile} from 'ng-zorro-antd';
import {Observable, Observer} from 'rxjs';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {REQUEST} from '@core/config/config';
import {UtilService} from '@core/service/util.service';

export const DEFAULT_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => FileUploadComponent),
  multi: true
};

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
  providers: [DEFAULT_VALUE_ACCESSOR]
})
export class FileUploadComponent implements ControlValueAccessor {
  defaultUploadUrl = REQUEST.UPLOAD_URL;
  previewImage: string;
  previewVisible = false;
  fileList = [];
  value: string = null;
  @Input() isNecessary = true;
  @Input() title: string;
  @Input() uploadUrl: string;
  propagateChange = (_: any) => { };

  constructor(
    private msg: NzMessageService,
    private util: UtilService
  ) { }

  writeValue(value: any): void {
    if (!!value) {
      this.value = value;
      this.showError();
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  beforeUpload = (file: File): Observable<any> => {
    this.defaultUploadUrl = this.uploadUrl || this.defaultUploadUrl;
    return new Observable((observer: Observer<boolean>) => {
      const isJPG = file.type === 'image/jpeg';
      if (!isJPG) {
        this.msg.error('您只能上传JPG格式文件!');
        observer.complete();
        return;
      }
     
      const isLt1Mtj = file.size / 1024/ 1024 < 10;
      if (!isLt1Mtj) {
        this.msg.error('图片大小应小于10MB!');
        observer.complete();
        return;
      }
      // let reads = new FileReader();
      // reads.readAsDataURL(file);
      // reads.onload = (e) => {
      //   let image = new Image();
      //   image.src = e.target['result'].toString();
      //   image.onload = (e) => {
      //     if(e['path'][0].width * e['path'][0].height >= 600*400){
      //       this.msg.error('图片尺寸应小于等于600px*400px!');
      //       return;
      //     }
      //   }
      // };
      observer.next(isJPG && isLt1Mtj);
      observer.complete();
    });
  }

  handlePreview = (file: UploadFile): void => {
    this.previewImage = file.url || file.thumbUrl;
    if (this.previewImage.indexOf('error.png') === -1) {
      this.previewVisible = true;
    } else {
      this.msg.error('图片已丢失或服务器错误！');
    }
  }

  handleChange(info: { file: UploadFile }): void {
    if (info.file.status === 'done') {
      this.value = info.file.response.path;
    } else if (info.file.status === 'removed') {
      this.value = null;
    } else if (info.file.status === 'error') {
      this.msg.error(info.file.error.error.message);
    }
    this.propagateChange(this.value);
  }

  /**
   * 预加载图片，避免出现加载失败的情况
   */
  showError(): void {
    const url = REQUEST.DISPLAY_PIC_BASE + this.value;
    this.util.preloadImage(url).then((e) => {
      this.fileList = [{url: url || ''}];
    }).catch((e) => {
      this.fileList = [{url: 'assets/img/error.png'}];
    });
  }
}
